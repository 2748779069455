import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MessageService } from 'src/app/core/message.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { LocationService } from 'src/app/shared/services';
import { CommonApiService } from 'src/app/shared/services/new-appnt-service/common-api.service';
import { LANGUAGES_LIST } from 'src/app/shared/enums';
import {
  LocationClient,
  SettingsClient,
  ProviderClient,
  DentistClient,
  PatientClient,
  ClinicClient,
  ContactClient,
  EmployerDto,
  SearchClient,
  GenderEnum,
  PolicyDto,
  RelationshipTypeEnum,
  CreatePatientDto,
  ContactAddressDto2,
  PatientPhoneDto,
  PatientEmailDto,
  AlertsDto,
  RelationshipDto,
  ContactPhoneDto2,
  ContactEmailDto2,
  SearchContactQueryDto,
  SearchContactDto,
  ContactDto,
  PhoneTypeEnum,
  ContactPhoneDto,
  EmailTypeEnum
} from 'src/app/shared/services/api.service';
import { ScheduleService } from 'src/app/shared/services/schedule/schedule.service';
import { PatientsStoreActions, RootStoreState, LocationsStoreSelectors, LocationsStoreActions, AuthStoreSelectors } from 'src/app/root-store';
import { PATIENT_TITLES } from 'src/app/shared/enums/PatientTitleType';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as moment from 'moment-timezone';
import { forkJoin } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-new-patient',
  templateUrl: './create-new-patient.component.html',
  styleUrls: ['./create-new-patient.component.css']
})
export class CreateNewPatientComponent implements OnInit, OnDestroy {


  @Output() showCreateAppnt = new EventEmitter();
  @Output() showProcedureEvent = new EventEmitter();
  @Output() passPatientInfo = new EventEmitter();
  @Output() onAbandon = new EventEmitter();
  @Output() afterSubmitPatient = new EventEmitter();

  emailMask = emailMask;

  @Input() patientPopUpOpen: any;
  @Input() selectedLocation: any;

  responsibleBackup: any = {};

  preferredPracArr: any[] = [{ "id": '', "option": "Preferred Location" }];

  preferredDoctorArr: any[] = [{ "id": '', "option": "Preferred Doctor" }];

  genderArr: any[] = [];
  Gender = GenderEnum;

  private includeLanguages: string[] = ['en', 'es'];
  LANGUAGES_LIST = _.pickBy(LANGUAGES_LIST, (value, key) => {
    return this.includeLanguages.includes(key);
  });


  phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  dobmask: any = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  ssnmask: any = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  zipcodeMask: any = [/\d/, /\d/, /\d/, /\d/, /\d/];
  statemask: any = [/[a-zA-Z]/, /[a-zA-Z]/];

  showConfirmation: boolean = false;
  showResponsibleParty_modal: boolean = false;

  errorMessage = 'Are you sure you want to abandon patient information before saving?';
  abandonButton: boolean = false;


  showPatientResult: boolean = false;
  filterPatient: any[] = [];

  patient_data: any = [];

  searchEnable: boolean = true;
  getSearchData: boolean = false;
  patientId: any = '';
  contactId: any = '';
  patient: any = {};
  treatmentStatusArr: any[] = [];
  showrerrefProf: boolean = false;
  showrerrefPatient: boolean = false;
  referralProfnl: any[] = [];
  referralPatient: any[] = [];
  formValueChanged: boolean = false;
  showUpdateConfirmation: boolean = false;

  defaultLang: any = "en";
  defaultTreatmentStatus: any = '';

  panelOpenState: boolean = false;
  step: any = 0;

  accordionPanel: any[] = [{ "title": "patient information" }];
  addNewResp: boolean = false;
  showNotification_modal: boolean = false;
  multipleResp: boolean = false;
  patientRespIndx: any = 0;

  insuranceCompies: any[] = [];

  newResponsiblePartyform: FormGroup;
  patientResp: any[] = [];
  contactETag: any = '';
  addedRespCounter: any = 0;
  btnState: any = "save";
  mdclAlrtArr: any[] = [];
  filterMdclAlrtArr: any[] = [];
  professionalList: any[] = [];
  selectedProfesnl: any = {};
  showProfessnlPopup: boolean = false;
  showPatientlPopup: boolean = false;
  patientAllData: any[] = [];
  selectedReferringPatient: any = {};
  employers: EmployerDto[] = [];
  selectedInsurance: any = "";
  initSearch: boolean = false;
  searchExistingPatients: SearchContactDto[] = [];
  searchExistingResponsibleParties: SearchContactDto[] = [];
  isExistingPatient: boolean = false;
  isExistingResponsibleParty: boolean = false;
  isSearchLoading: boolean = false;
  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  titleArr: { name: string; }[] = Object.entries(PATIENT_TITLES).map(([key, value]) => ({
    name: value.name,
  }));

  filterTitleArr: any[];
  isLoading: boolean;
  filterInsuranceArr: any[] = [];
  insuranceSortType: any = 'asc';
  patientPolicies: PolicyDto[] = [];
  showInsuranceSection: boolean = true;
  showAddPolicyBtn: boolean = true;
  selectedPolicy: PolicyDto;

  @ViewChild("patientsDentist", { static: false }) patientsDentist: ElementRef;
  @ViewChild("professionalthank", { static: false }) professionalthank: ElementRef;
  @ViewChild("patientthank", { static: false }) patientthank: ElementRef;

  patientForm: FormGroup;
  respPartyForm: FormGroup;
  relationshiparr: any[] = [...Object.entries(RelationshipTypeEnum).map(([key, value]) => ({ id: key, name: value }))];
  newPatientData: CreatePatientDto;
  responsibleSelectedIndex: number;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private dataService: MessageService,
    private locationService: LocationService,
    private locationClient: LocationClient,
    private settingsClient: SettingsClient,
    private providerClient: ProviderClient,
    private dentistClient: DentistClient,
    private patientClient: PatientClient,
    private clinicClient: ClinicClient,
    private contactClient: ContactClient,
    private scheduleService: ScheduleService,
    private apiService: CommonApiService,
    private _searchClient: SearchClient,
    private _store$: Store<RootStoreState.State>,
    private _snackbar: MatSnackBar,
  ) {
    this.selectedLocation = this.scheduleService.selectedLocation;
  }

  ngOnInit() {
    this.initializePatientForm();
    this.initializeResponsiblePartyform();
    this.getLanguage();
    this.getLocations();
    this.getPatientStatusGroup();
    this.getInsurance();
    this.getMedicalAlert();
  }

  get respPartyFormControl() { return this.respPartyForm.controls; }
  get respPartyFormField() { return this.respPartyFormControl.respParties as FormArray }

  ngOnDestroy() {
    this._destroy$.next(true);
  }


  initializePatientForm() {
    this.patientForm = this.formBuilder.group({
      patientId: [''],
      patientETag: [''],
      patientStatusId: [''],
      patientFirstName: ['', Validators.required],
      patientMiddleName: [''],
      patientLastName: ['', Validators.required],
      patientPreferred: [''],
      patientTitle: [''],
      patientdob: ['', [Validators.pattern('^\\d{2}\\/\\d{2}\\/\\d{4}$')]],
      gender: ['', Validators.required],
      patientSsn: [''],
      medicalAlert: [''],
      notes: [''],
      dentistId: [''],
      dentistClinicId: [''],
      referringProfessional: [''],
      referringClinicId: [''],
      preferredLocationId: [''],
      preferredProviderId: [''],
      languageKey: [''],
      insurance: [''],
      insuranceName: [''],
      groupNumber: [''],
      employerId: [''],
      insuranceNotes: [''],
      payorId: [''],
      patientEmp: [''],
      contactId: [''],
      contactETag: [''],
      patientEmailId: [''],
      patientEmail: ['', [Validators.email]],
      patientEmailETag: [''],
      patientCellPhoneId: [''],
      patientCellPhone: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
      patientCellPhoneETag: [''],
      patientPrimaryAddressId: [''],
      patientStreetAd: [''],
      patientCity: [''],
      patientState: [''],
      patientZipCode: [''],
      patientPrimaryAddressEtag: [''],
    });

    this.selectedLocation$.pipe()
      .pipe(takeUntil(this._destroy$))
      .subscribe(res => {
        if (res != undefined) {

          this.patientForm.patchValue({
            patientCity: res.address.city,
            patientState: res.address.state
          });

        }
      });

    this.filterTitleArr = this.titleArr;
  }

  initializeResponsiblePartyform() {
    this.respPartyForm = this.formBuilder.group({
      respParties: new FormArray([])
    });

    this.addNewRespParty();
  }


  dateMask() {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
    return { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], keepCharPositions: true, pipe: autoCorrectedDatePipe };
  }

  cancel() {
    this.showConfirmation = true;
    this.errorMessage = 'Are you sure you want to abandon patient information before saving?';
    this.abandonButton = true;

  }

  btnAction(action) {
    if (action == 'abandon') {
      this.onAbandon.emit();
    } else {
      if (action == 'back') {
        this.showConfirmation = false;
      } else {
        if (this.patientPopUpOpen == 'emptyCell') {
          this.showProcedureEvent.emit("showProcedureEvent");
        } else {
          this.showCreateAppnt.emit("showcreateappnt");
        }

      }
    }
  }

  getLanguage() {
    this.patientForm.patchValue({
      "languageKey": this.defaultLang
    })

  }

  getLocations() {
    this.locationService.getLocations()
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((locations: any) => {
        this.preferredPracArr = [{ "id": '', "option": "Preferred Location" }];
        let locationsdata = locations;

        locationsdata.map(item => {
          if (item.isActive) {
            this.preferredPracArr.push({ 'id': item.id, 'option': item.shortName })
          }
        });

        this.patientForm.patchValue({
          "preferredLocationId": this.selectedLocation.id
        });

        this.getPreferredLocation(this.selectedLocation);

      });
  }

  getPatientStatusGroup() {
    this.settingsClient.settings_GetPatientStatusGroups(true)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(
        (res) => {

          this.treatmentStatusArr = [];
          let resp: any = JSON.parse(JSON.stringify(res));

          resp.map(item => {
            if (item.name == '1000' || item.name == '2000') {
              item.patientStatuses.map(patientStatus => {
                this.treatmentStatusArr.push({ "id": patientStatus.id, "option": `${patientStatus.code} - ${patientStatus.description}` });
              });

              if (this.treatmentStatusArr.length > 0) {
                this.patientForm.patchValue({
                  patientStatusId: this.treatmentStatusArr[0].id
                })
              }
            }
          })
        },
        (err) => {
          console.log(err)
        }
      )
  }

  getPreferredLocation(elem) {
    this.patientForm.patchValue({
      preferredLocationId: elem.id,
    });

    let doctors: any[] = [];

    this.providerClient.provider_GetProviders(null, elem.id, true, 100, undefined, null)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(
        (res) => {
          this.preferredDoctorArr = [];
          doctors = JSON.parse(JSON.stringify(res));
          doctors = _.filter(doctors, 'isActive');

          _.each(doctors, d => {
            this.preferredDoctorArr.push({
              'id': d.id,
              'option': `${d.firstName} ${d.lastName}`,
              'nickname': d.nickName
            })
          });

          if (this.preferredDoctorArr.length == 1) {
            let provider = _.head(this.preferredDoctorArr);

            this.patientForm.patchValue({
              preferredProviderId: provider.id
            });
          }
          else {

            this.patientForm.patchValue({
              preferredProviderId: this.patient.preferredProviderId,
            });
          }
        },
        (err) => {
          console.log(err)
        }
      )

  }

  getPreferredDoctor(elem) {
    this.patientForm.patchValue({
      preferredProviderId: elem.id,
    });
  }


  backToPrevious(evt) {
    if (evt && evt.patientid) {
      this.afterSubmitPatient.emit(evt.patientid);
    }
    this.onAbandon.emit();
  }

  getInsurance() {
    this.settingsClient.settings_GetInsuranceCompanies()
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(
        (res) => {
          this.insuranceCompies = [];
          let insuranceCompanies = _.filter(res, 'isActive');
          _.each(insuranceCompanies, insuranceCompany => {
            let shortName = !insuranceCompany.shortName || !insuranceCompany.shortName.trim() ? insuranceCompany.name : insuranceCompany.shortName;
            this.insuranceCompies.push({
              "id": insuranceCompany.id,
              "shortName": shortName,
              "name": insuranceCompany.name,
              "addressLine1": insuranceCompany.addressLine1,
              "addressLine2": insuranceCompany.addressLine2,
              "city": insuranceCompany.city,
              "state": insuranceCompany.state,
              "zip": insuranceCompany.zip,
              "payorNumber": insuranceCompany.payorNumber
            })
          });
          this.filterInsuranceArr = this.insuranceCompies;
          this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
          this.insuranceSortType = 'asc';
        },
        (err) => {
          console.log(err)
        }
      )
  }

  searchMedicalAlert(_searchkeyword) {
    let searchkeyword: any = _searchkeyword.toLowerCase();
    if (searchkeyword.length > 0) {
      this.filterMdclAlrtArr = this.mdclAlrtArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
      if (this.filterMdclAlrtArr.length == 0) {
        this.filterMdclAlrtArr = this.mdclAlrtArr;
      }

    } else {
      this.filterMdclAlrtArr = [];
    }
  }

  searchTitle(_searchkeyword) {
    let searchkeyword: any = _searchkeyword.toLowerCase();
    if (searchkeyword.length > 0) {
      this.filterTitleArr = this.titleArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
      if (this.filterTitleArr.length == 0) {
        this.filterTitleArr = this.titleArr;
      }

    } else {
      this.filterTitleArr = this.titleArr;
    }
  }

  onFocusTile() {
    this.filterTitleArr = this.titleArr;
  }

  getMedicalAlert() {
    this.settingsClient.settings_GetMedicalAlerts(null)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(
        (res) => {
          this.mdclAlrtArr = res.filter(item => {
            return item.isActive == true
          })
        },
        (err) => {
          console.log(err)
        }
      )
  }

  getPatientDentist(searchobj) {
    this.patientsDentist.nativeElement.value = searchobj.dentist.displayName;

    this.patientForm.patchValue({
      dentistId: searchobj.dentist.id,
      dentistClinicId: searchobj.clinic.id
    })
  }

  insuranceCompanySelected(id: number, isForPatient: boolean, rowIndx?: number) {
    let ins = this.insuranceCompies.find((ic) => ic.id == id);

    if (isForPatient) {
      this.patientForm.patchValue({
        "insuranceName": ins.shortName,
        "insurance": ins.id,
        "payorId": ins.payorNumber
      });
    } else {

      this.respPartyFormField.controls[rowIndx].patchValue({
        "responsibleInsuranceName": ins.shortName,
        "responsibleInsurance": ins.id,
        "payorId": ins.payorNumber
      })
    }
  }

  searchInsurance(_searchkeyword) {
    let searchkeyword: any = _searchkeyword.toLowerCase();
    if (searchkeyword.length > 0) {
      this.filterInsuranceArr = this.insuranceCompies.filter(option => (option.name.toLowerCase().includes(searchkeyword) || option.shortName.toLowerCase().includes(searchkeyword)));
      if (this.filterInsuranceArr.length == 0) {
        this.filterInsuranceArr = this.insuranceCompies;
      }
    } else {
      this.filterInsuranceArr = this.insuranceCompies;
    }
    this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
    this.insuranceSortType = 'asc';
  }

  sortInsurance(sortOn) {
    this.insuranceSortType = this.insuranceSortType == 'asc' ? 'desc' : 'asc';
    if (sortOn == 'shortname') {
      this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], [this.insuranceSortType]);
    } else {
      this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.name.toLowerCase()], [this.insuranceSortType]);
    }
  }

  onFocusInsurance() {
    this.filterInsuranceArr = this.insuranceCompies;
    this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
    this.insuranceSortType = 'asc';
  }

  getInsurnaceCompanyById(id) {
    let insComp = this.insuranceCompies.find((ic) => ic.id == id);
    return insComp;
  }

  getLocationById(locationId) {
    let _location = this.preferredPracArr.find(location => location.id == locationId);

    if (_location) {
      return _location.option;
    }

    return null;
  }

  getDoctorPreferenceById(doctorId) {
    let _doctor = this.preferredDoctorArr.find(doctor => doctor.id == doctorId);
    if (_doctor) {
      return _doctor.nickname;
    }

    return null;
  }

  getLanguageByKey(key) {
    let _language = this.LANGUAGES_LIST[key];
    if (_language) {
      return _language.name;
    }

    return null;
  }

  getPatientStatus(patientStatusId) {
    let patientStatus = this.treatmentStatusArr.find(patientStatus => patientStatus.id == patientStatusId);
    if (patientStatus) {
      return patientStatus.option;
    }

    return null;
  }

  getDentistSerarchValue(searchobj) {
    this.professionalthank.nativeElement.value = searchobj.dentist.displayName;
    this.patientForm.patchValue({
      referringProfessional: searchobj.dentist.id,
      referringClinicId: searchobj.clinic.id
    })
  }

  getPatientSerarchValue(searchobj) {
    this.patientthank.nativeElement.value = `${searchobj.patientModel.firstName} ${searchobj.patientModel.lastName}`;
    this.patientForm.patchValue({
      referringPatient: searchobj.patientModel.patientId
    })
  }

  addNewRespParty() {
    this.respPartyFormField.push(this.formBuilder.group({
      responsibleContactId: [''],
      responsibleContactETag: [''],
      responsibleFirstName: ['', Validators.required],
      responsibleMiddleName: [''],
      responsibleLastName: ['', Validators.required],
      responsiblePreferred: [''],
      responsibleTitle: [''],
      relationshipType: ['', Validators.required],
      responsibleStreet: [''],
      responsibleCity: [''],
      responsibleState: [''],
      responsibleZip: [''],
      responsibleAddressEtag: [''],
      responsibleAddressId: [''],
      responsibleDob: ['', [Validators.pattern('^\\d{2}\\/\\d{2}\\/\\d{4}$')]],
      responsibleGender: [''],
      responsibleSsn: [''],
      responsiblePhone: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
      responsibleIsPrimary: [''],
      responsiblePhoneType: [''],
      responsibleExtension: [''],
      responsiblePhoneEtag: [''],
      responsiblePhoneId: [''],
      responsibleEmail: ['', [Validators.email]],
      responsibleEmailEtag: [''],
      responsibleEmailId: [''],
      responsibleEmailType: [''],
      payorId: [''],
      responsibleInsurance: [''],
      responsibleInsuranceName: [''],
      responsibleGroup: [''],
      responsibleEmpid: [''],
      responsibleEmp: [''],
      responsibleInsuranceNotes: ['']
    }))
  }

  saveAndFinish() {
    let patientFormValue = this.patientForm.value;

    // Validate Patient Form
    if (!this.patientForm.valid) {
      this.showConfirmation = true;
      this.abandonButton = false;

      if (this.patientForm.get('patientFirstName').hasError('required')) {
        this.errorMessage = 'Ooops! \n' +
          'add a first name for this patient.';
      } else if (this.patientForm.get('patientLastName').hasError('required')) {
        this.errorMessage = 'Ooops! \n' +
          'add a last name for this patient.';
      } else if (this.patientForm.get('gender').hasError('required')) {
        this.errorMessage = 'Ooops! \n' +
          'select gender for this patient.';
      } else if (this.patientForm.get('patientEmail').hasError('email')) {
        this.errorMessage = 'Ooops! \n' +
          'add a valid email for this patient.';
      } else if (this.patientForm.get('patientCellPhone').hasError('pattern')) {
        this.errorMessage = 'Ooops! \n' +
          'add a valid phone for this patient.';
      } else if (this.patientForm.value.patientEmail == '' && this.patientForm.value.patientCellPhone == '') {
        this.errorMessage = 'add a valid email / phone for this patient.';
      } else if (this.patientForm.get('patientdob').hasError('pattern')) {
        this.errorMessage = 'Ooops! \n' +
          'add a valid date of birth for this patient.';
      }

      return;
    }

    // Validate Responsible Party Forms
    let respParties = [];

    if (this.respPartyFormField
      && this.respPartyFormField.controls
      && this.respPartyFormField.controls.length > 0) {
        let errorInRespSection: boolean = false;

        this.respPartyFormField.controls.forEach((formGroup: FormGroup) => {

          // Skip if error detected in previous forms
          if (errorInRespSection) {
            return;
          }

          // Skip if form values are all empty
          if (Object.values(formGroup.value).every(x => (x === null || x === ''))) {
            return;
          }

          if (!formGroup.valid) {
            this.showConfirmation = true;
            this.abandonButton = false;

            if (formGroup.get('responsibleFirstName').hasError('required')) {
              this.errorMessage = 'Ooops! \n' +
                'add a first name for this guardian / parent.';
            } else if (formGroup.get('responsibleLastName').hasError('required')) {
              this.errorMessage = 'Ooops! \n' +
                'add a last name for this guardian / parent.';
            } else if (formGroup.get('relationshipType').hasError('required')) {
              this.errorMessage = 'Ooops! \n' +
                'select relation for this guardian / parent.';
            } else if (formGroup.get('responsibleEmail').hasError('email')) {
              this.errorMessage = 'Ooops! \n' +
                'add a valid email for this guardian / parent.';
            } else if (formGroup.get('responsiblePhone').hasError('pattern')) {
              this.errorMessage = 'Ooops! \n' +
                'add a valid phone for this guardian / parent.';
            } else if (formGroup.get('responsibleDob').hasError('pattern')) {
              this.errorMessage = 'Ooops! \n' +
                'add a valid date of birth for this guardian / parent..';
            }

            errorInRespSection = true;
          } else if (formGroup.value.responsibleEmail == '' && formGroup.value.responsiblePhone == '') {
            this.showConfirmation = true;
            this.abandonButton = false;

            this.errorMessage = 'add a valid email / phone for this guardian / parent.';

            errorInRespSection = true;
          } else {
            respParties.push(formGroup.value);
          }

        });

        if (errorInRespSection) {
          return;
        }
    }

    // Check if there are no responsible parties
    if (respParties.length == 0) {
      if (patientFormValue.patientEmail == '' && patientFormValue.patientCellPhone == '') {
        this.showConfirmation = true;
        this.abandonButton = false;

        this.errorMessage = 'add a valid email / phone for this patient.';

        return
      }
    }

    if (patientFormValue.patientId > 0 || patientFormValue.contactId > 0) {
      combineLatest(
        patientFormValue.patientId > 0 ? this.patientClient.patient_GetPatient(patientFormValue.patientId) : of(null),
        patientFormValue.contactId > 0 ? this.contactClient.contact_GetContact(patientFormValue.contactId) : of(null)
      )
        .pipe(
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe(([patient, contact]) => {
          if (patient) {
            this.patientForm.patchValue({
              patientETag: patient.eTag
            });
          }

          if (contact) {
            this.patientForm.patchValue({
              contactETag: contact.eTag
            });

            if (contact.addresses && contact.addresses.length > 0) {
              this.patientForm.patchValue({
                patientPrimaryAddressEtag: contact.addresses[0].eTag
              });
            }

            if (contact.emails && contact.emails.length > 0) {
              this.patientForm.patchValue({
                patientEmailETag: contact.emails[0].eTag
              });
            }

            if (contact.phones && contact.phones.length > 0) {
              this.patientForm.patchValue({
                patientCellPhoneETag: contact.phones[0].eTag
              });
            }
          }

          this.createPatient(respParties);
        });
    }
    else {
      this.createPatient(respParties);
    }
  }

  changeSameaddr_chk(_respPartyForm) {
    let patientFormValue = this.patientForm.value;

    _respPartyForm.patchValue({
      responsibleStreet: patientFormValue.patientStreetAd,
      responsibleCity: patientFormValue.patientCity,
      responsibleState: patientFormValue.patientState,
      responsibleZip: patientFormValue.patientZipCode
    });
  }

  createPatient(respParties?: any[]) {
    let patientFormValue = this.patientForm.value;

    let primaryAddress = new ContactAddressDto2();
    primaryAddress.addressLine1 = patientFormValue.patientStreetAd;
    primaryAddress.city = patientFormValue.patientCity;
    primaryAddress.state = patientFormValue.patientState;
    primaryAddress.zip = patientFormValue.patientZipCode;
    primaryAddress.id = patientFormValue.patientPrimaryAddressId ? patientFormValue.patientPrimaryAddressId : 0;
    primaryAddress.eTag = patientFormValue.patientPrimaryAddressEtag;

    let patientPhoneDto = new PatientPhoneDto();
    patientPhoneDto.number = this.clearPhones(patientFormValue.patientCellPhone);
    patientPhoneDto.id = patientFormValue.patientCellPhoneId ? patientFormValue.patientCellPhoneId : 0;
    patientPhoneDto.eTag = patientFormValue.patientCellPhoneETag;
    patientPhoneDto.phoneType = PhoneTypeEnum.Mobile;

    let patientEmailDto = new PatientEmailDto();
    patientEmailDto.emailAddress = patientFormValue.patientEmail;
    patientEmailDto.id = patientFormValue.patientEmailId ? patientFormValue.patientEmailId : 0;
    patientEmailDto.eTag = patientFormValue.patientEmailETag;

    let medicalalert = new AlertsDto();
    medicalalert.medical = [patientFormValue.medicalAlert]


    let newPatient: CreatePatientDto = new CreatePatientDto();
    newPatient.patientStatusId = patientFormValue.patientStatusId;
    newPatient.firstName = patientFormValue.patientFirstName;
    newPatient.middleName = patientFormValue.patientMiddleName;
    newPatient.lastName = patientFormValue.patientLastName;
    newPatient.nickname = patientFormValue.patientPreferred;
    newPatient.title = patientFormValue.patientTitle;
    newPatient.primaryAddress = primaryAddress;
    newPatient.dob = patientFormValue.patientdob ? moment(patientFormValue.patientdob).toDate() : null;
    newPatient.gender = Object.values(GenderEnum).includes(patientFormValue.gender) ? patientFormValue.gender : GenderEnum.Unknown;
    newPatient.ssn = patientFormValue.patientSsn;
    newPatient.mobilePhone = patientPhoneDto.number ? patientPhoneDto: null;
    newPatient.primaryEmail = patientEmailDto.emailAddress ? patientEmailDto : null;
    newPatient.alerts = medicalalert;
    newPatient.comments = patientFormValue.notes;
    newPatient.dentistId = patientFormValue.dentistId;
    newPatient.dentistClinicId = patientFormValue.dentistClinicId;
    newPatient.referringDentistId = patientFormValue.referringProfessional;
    newPatient.referringClinicId = patientFormValue.referringClinicId;
    newPatient.preferredLocationId = patientFormValue.preferredLocationId;
    newPatient.preferredProviderId = patientFormValue.preferredProviderId;
    newPatient.languageKey = patientFormValue.languageKey;
    newPatient.contactId = patientFormValue.contactId;
    newPatient.contactETag = patientFormValue.contactETag;
    newPatient.id = patientFormValue.patientId;
    newPatient.eTag = patientFormValue.patientETag;

    this.isLoading = true;

    const savePatient = newPatient.id ?
      this.locationClient.location_PutPatient(this.selectedLocation.id, newPatient.id, newPatient, null) :
      this.locationClient.location_PostPatient(this.selectedLocation.id, newPatient, null);

    savePatient
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(resp => {
        this.newPatientData = resp;
        this.isLoading = true;

        this._store$.dispatch(
          PatientsStoreActions.PatientViewed({
            patientId: resp.id,
            locationId: resp.locationId,
            name: `${resp.firstName} ${resp.lastName}`,
          })
        );

        if (patientFormValue.insurance) {
          let policyDto = new PolicyDto();
          policyDto.insuranceCompanyId = patientFormValue.insurance;
          policyDto.groupPolicyNumber = patientFormValue.groupNumber;
          policyDto.employerId = patientFormValue.employerId;;
          policyDto.employerName = patientFormValue.patientEmp;
          this.contactClient.contact_PostPolicy(this.newPatientData.contactId, policyDto)
            .pipe(
              take(1),
              takeUntil(this._destroy$)
            )
            .subscribe(resp => {
              this.isLoading = false;
              this.saveResponsibleParties(respParties)
            },
              err => {
                this.isLoading = false;
              })
        } else {
          this.saveResponsibleParties(respParties);
        }

      })
  }

  saveResponsibleParties(respParties?: any[]) {
    if (!respParties || respParties.length < 1) {
      this.showNotification_modal = true;
      this.isLoading = false;
      return;
    };
    let respPartySaveAction = [];
    this.isLoading = true;

    respParties.map(respParty => {
      let primaryAddress = new ContactAddressDto2();

      if (respParty.responsibleStreet || respParty.responsibleCity || respParty.responsibleState || respParty.responsibleZip) {
        primaryAddress.addressLine1 = respParty.responsibleStreet;
        primaryAddress.city = respParty.responsibleCity;
        primaryAddress.state = respParty.responsibleState;
        primaryAddress.zip = respParty.responsibleZip;
        primaryAddress.eTag = respParty.responsibleAddressEtag;
        primaryAddress.id = respParty.responsibleAddressId ? respParty.responsibleAddressId : 0;
      } else {
        primaryAddress = null;
      }

      let relationPhoneDto = new ContactPhoneDto2();
      relationPhoneDto.number = this.clearPhones(respParty.responsiblePhone);
      relationPhoneDto.id = respParty.responsiblePhoneId ? respParty.responsiblePhoneId : 0;
      relationPhoneDto.eTag = respParty.responsiblePhoneEtag;
      relationPhoneDto.isPrimary = respParty.responsibleIsPrimary;
      relationPhoneDto.extension = respParty.responsibleExtension;
      relationPhoneDto.phoneType = PhoneTypeEnum.Mobile;

      let relationEmailDto = new ContactEmailDto2();
      relationEmailDto.emailAddress = respParty.responsibleEmail;
      relationEmailDto.id = respParty.responsibleEmailId ? respParty.responsibleEmailId : 0;
      relationEmailDto.emailType = respParty.responsibleEmailType;
      relationEmailDto.eTag = respParty.responsibleEmailEtag;
      relationEmailDto.emailType = EmailTypeEnum.Personal;

      let relationshipDto: RelationshipDto = new RelationshipDto();
      relationshipDto.firstName = respParty.responsibleFirstName;;
      relationshipDto.middleName = respParty.responsibleMiddleName;
      relationshipDto.lastName = respParty.responsibleLastName;
      relationshipDto.nickname = respParty.responsiblePreferred;
      relationshipDto.title = respParty.responsibleTitle;
      relationshipDto.relationshipType = respParty.relationshipType;
      relationshipDto.primaryAddress = primaryAddress;
      relationshipDto.dob = respParty.responsibleDob ? moment(respParty.responsibleDob).toDate() : null;
      relationshipDto.gender = Object.values(GenderEnum).includes(respParty.responsibleGender) ? respParty.responsibleGender : GenderEnum.Unknown;
      relationshipDto.ssn = respParty.responsibleSsn;
      relationshipDto.mobilePhone = relationPhoneDto.number ? relationPhoneDto : null;
      relationshipDto.primaryEmail = relationEmailDto.emailAddress ? relationEmailDto : null;
      relationshipDto.insuranceCompanyId = respParty.responsibleInsurance;
      relationshipDto.groupPolicyNumber = respParty.responsibleGroup;
      relationshipDto.employerId = respParty.responsibleEmpid;
      relationshipDto.employerName = respParty.responsibleEmp;
      relationshipDto.insuranceNotes = respParty.responsibleInsuranceNotes;
      relationshipDto.contactETag = respParty.responsibleContactETag;
      relationshipDto.contactId = respParty.responsibleContactId;

      respPartySaveAction.push(
        this.locationClient.location_PostRelationship(this.selectedLocation.id, this.newPatientData.id, relationshipDto, null)
      );
    })

    forkJoin(respPartySaveAction)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(resp => {
        this.showNotification_modal = true;
        this.isLoading = false;
      },
        err => {
          this.isLoading = false;
        })
  }

  clearPhones(phoneNumber) {
    if (!phoneNumber) return '';
    return phoneNumber.replace(/[()-]/g, '').replace(/\s/g, '')
  }

  searchExistingPatient() {
    let patientForm: any = this.patientForm.value;
    let firstName: any = patientForm.patientFirstName;
    let lastName: any = patientForm.patientLastName;

    if (firstName.length > 0 && lastName.length > 0) {
      this.getPatientList(firstName, lastName);
    }
  }

  getPatientList(firstName, lastName) {
    this.isLoading = true;
    const query = new SearchContactQueryDto();
    query.firstName = firstName;
    query.lastName = lastName;

    this._searchClient
      .search_PostContacts(query, 500, 0, true)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(
        (res) => {
          this.searchExistingPatients = res;
          this.isExistingPatient = this.searchExistingPatients.length > 0;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  closeSearchPanel() {
    this.isExistingPatient = false;
    this.searchExistingPatients = []
  }

  searchPatientContactSelected(searchContact: SearchContactDto) {
    this.patientForm.patchValue({
      patientTitle: searchContact.title,
      patientFirstName: searchContact.firstName,
      patientLastName: searchContact.lastName,

      gender: searchContact.gender,
      patientdob: searchContact.dob ? moment(searchContact.dob).tz('UTC').format('MM/DD/YYYY') : null,
      patientMiddleName: searchContact.middleName,
      patientPreferred: searchContact.nickname,

      patientSearchFamily: '',
      patientEditFamily: '',
      languageKey: searchContact.languageKey,

      contactId: searchContact.contactId,
      contactETag: searchContact.contactETag,

      patientEmailId: searchContact.primaryEmail && searchContact.primaryEmail.emailAddress ? searchContact.primaryEmail.id : null,
      patientEmail: searchContact.primaryEmail && searchContact.primaryEmail.emailAddress ? searchContact.primaryEmail.emailAddress : null,
      patientEmailETag: searchContact.primaryEmail && searchContact.primaryEmail.emailAddress ? searchContact.primaryEmail.eTag : null,

      patientCellPhoneId: searchContact.primaryPhone ? searchContact.primaryPhone.id : null,
      patientCellPhone: searchContact.primaryPhone ? searchContact.primaryPhone.number : null,
      patientCellPhoneEtag: searchContact.primaryPhone ? searchContact.primaryPhone.eTag : null,

      patientPrimaryAddressId: searchContact.primaryAddress ? searchContact.primaryAddress.id : null,
      patientStreetAd: searchContact.primaryAddress ? searchContact.primaryAddress.addressLine1 : null,
      patientCity: searchContact.primaryAddress ? searchContact.primaryAddress.city : null,
      patientState: searchContact.primaryAddress ? searchContact.primaryAddress.state : null,
      patientZipCode: searchContact.primaryAddress ? searchContact.primaryAddress.zip : null,
      patientPrimaryAddressEtag: searchContact.primaryAddress ? searchContact.primaryAddress.eTag : null,

      patientId: searchContact.patientId ? searchContact.patientId : 0,
      patientETag: searchContact.patientETag,
    });

    this.closeSearchPanel();
  }

  searchExistingRelationship(i: number) {
    let respForm: any = this.respPartyFormField.controls[i].value;
    let firstName: any = respForm.responsibleFirstName;
    let lastName: any = respForm.responsibleLastName;

    if (firstName.length > 0 && lastName.length > 0) {
      this.getExistingResponsibleParties(firstName, lastName);
      this.responsibleSelectedIndex = i;
    }
  }

  getExistingResponsibleParties(firstName, lastName) {
    this.isLoading = true;
    const query = new SearchContactQueryDto();

    query.firstName = firstName;
    query.lastName = lastName;

    this._searchClient
      .search_PostContacts(query, 500, 0, true)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((res) => {
        this.searchExistingResponsibleParties = res;
        this.isExistingResponsibleParty = this.searchExistingResponsibleParties.length > 0;

        this.isLoading = false;
      },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  selectExistingResponsibleParty(contactId: number) {
    if (!contactId || contactId == 0) return;

    this.contactClient.contact_GetContact(contactId, null)
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((contact: ContactDto) => {
        let primaryEmail = _.find(contact.emails, 'isPrimary');
        let primaryAddress = _.find(contact.addresses, 'isPrimary');
        let mobilePhone = _.find(contact.phones, { 'phoneType': PhoneTypeEnum.Mobile });
        let alternatePhones = _.filter(contact.phones, (phone: ContactPhoneDto) => {
          return mobilePhone && mobilePhone.id != phone.id;
        });

        this.respPartyFormField.controls[this.responsibleSelectedIndex].patchValue({
          responsibleContactId: contact.id,
          responsibleFirstName: contact.firstName,
          responsibleMiddleName: contact.middleName,
          responsibleLastName: contact.lastName,
          responsibleGender: contact.gender,
          responsibleDob: contact.dob ? moment(contact.dob).tz('UTC').format("MM/DD/YYYY") : null,
          responsibleSsn: contact.ssn,
          responsibleTitle: contact.title,
          responsibleStreet: primaryAddress ? primaryAddress.addressLine1 : null,
          responsibleCity: primaryAddress ? primaryAddress.city : null,
          responsibleState: primaryAddress ? primaryAddress.state : null,
          responsibleZip: primaryAddress ? primaryAddress.zip : null,
          responsibleAddressEtag: primaryAddress ? primaryAddress.eTag : null,
          responsibleAddressId: primaryAddress ? primaryAddress.id : 0,
          responsiblePhone: mobilePhone ? mobilePhone.number : null,
          responsiblePhoneEtag: mobilePhone ? mobilePhone.eTag : null,
          responsiblePhoneId: mobilePhone ? mobilePhone.id : 0,
          responsibleIsPrimary: mobilePhone ? mobilePhone.isPrimary : null,
          responsiblePhoneType: mobilePhone ? mobilePhone.phoneType : null,
          responsibleExtension: mobilePhone ? mobilePhone.extension : null,
          responsibleEmail: primaryEmail ? primaryEmail.emailAddress : null,
          responsibleEmailId: primaryEmail ? primaryEmail.id : 0,
          responsibleEmailType: primaryEmail ? primaryEmail.emailType : null,
          responsibleEmailEtag: primaryEmail ? primaryEmail.eTag : null,
          responsibleContactETag: contact.eTag,
          responsibleEmpid: contact.employerId,
          responsibleEmp: contact.employerName
        });
      },
        (err) => {
          console.log(err)
        }
      )

    this.isExistingResponsibleParty = false;
    this.searchExistingResponsibleParties = []
  }

  closeSearchResponsibleParties() {
    this.respPartyFormField.controls[this.responsibleSelectedIndex].patchValue({
      contactId: null,
      contactETag: null
    });

    this.isExistingResponsibleParty = false;
    this.searchExistingResponsibleParties = []
  }
}
