import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { routerRequestAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { AuthenticationService } from '@shared/services/auth/authentication.service';
import { RefreshTokenModel, TokenDto, UserClient } from '@shared/services/api.service';
import { EvoTokenParser } from '@shared/user-permissions';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { catchError, exhaustMap, filter, map, mapTo, share, switchMap, switchMapTo, tap, withLatestFrom } from 'rxjs/operators';
import * as RootStoreState from '../root-state';
import { UserStoreActions } from '../user-store';
import * as AuthStoreActions from './actions';
import * as AuthStoreSelectors from './selectors';
import { MatSnackBar } from '@angular/material';
import { TenantStoreActions } from '../tenant-store';

@Injectable({ providedIn: 'root' })
export class AuthenticationStoreEffectsService implements OnInitEffects {
  private _selectedUserTokenId$ = this._store$.select(AuthStoreSelectors.selectedUserTokenId);
  private _saveSession$ = this._store$.select(AuthStoreSelectors.selectSaveSession);
  private _userCredentials$ = this._store$.select(AuthStoreSelectors.selectCredentials);
  private _allTokens$ = this._store$.select(AuthStoreSelectors.selectAllTokens);

  constructor(
    private _actions$: Actions,
    private _userClient: UserClient,
    private _store$: Store<RootStoreState.State>,
    private _router: Router,
    private _authService: AuthenticationService,
    private _snackBar: MatSnackBar,
  ) {}
  ngrxOnInitEffects(): Action {
    return AuthStoreActions.LoginInit();
  }

  initEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.LoginInit),
      mapTo(this._authService.getBrowserTokenStorage()),
      filter(([tokenStorage, selectedTokenId, tenant]) => !!tokenStorage && tokenStorage.length > 0 && !!selectedTokenId && !!tenant),
      switchMap(([tokenStorage, selectedTokenId, tenant]) => {
        const tokens: TokenDto[] = tokenStorage.map((i) => TokenDto.fromJS(i));
        return of(
          AuthStoreActions.SetTenant({ tenantName: tenant && tenant.name, tenantKey: tenant && tenant.key, isPatient: tenant && tenant.isPatient }),
          AuthStoreActions.LoginSuccess({ userCredentials: tokens.find((t) => t.id == selectedTokenId) }),
          AuthStoreActions.AddTokens({ tokens: tokens.filter((t) => t.id != selectedTokenId) })
        );
      })
    )
  );

  loginRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.LoginRequest),
      switchMap((action) =>
        this._userClient.user_Token(action.authModel).pipe(
          map((result) => AuthStoreActions.LoginSuccess({ userCredentials: result })),
          catchError((error) => {
            if (error.response) {
              let response = JSON.parse(error.response)
              this._snackBar.open(response.title, "Ok", {
                duration: 3000,
              });
            }

            return of(AuthStoreActions.LoginFailure({ error }));
          })
        )
      ),
      share()
    )
  );

  navigatedToLoginEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        //When navigation request is made
        ofType(routerRequestAction),
        withLatestFrom(this._userCredentials$),
        tap(([action, credentials]) => {
          //redirect from root (aka login page) if already logged in
          if (credentials && action.payload.event.url == '/') {
            this._router.navigate(['/dashboard']);
          }
        })
      ),
    { dispatch: false }
  );

  loginSuccessEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthStoreActions.LoginSuccess, AuthStoreActions.ChangeTenantSuccess),
        withLatestFrom(this._allTokens$, this._selectedUserTokenId$, this._saveSession$),
        tap(([action, tokens, selectedTokenId, saveSession]) => {

          if (saveSession) {
            //this is technically redundant if a page was just refreshed as it was pulled from storage anyways
            this._authService.setBrowserTokenStorage(tokens);
            this._authService.setBrowserSelectedTokenStorage(selectedTokenId);
          }
          else
            this._authService.setBrowserTokenInfo(tokens, selectedTokenId);
        }),
        share()
      ),
    { dispatch: false }
  );

  loginTokenSetParsedEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.LoginSuccess, AuthStoreActions.RefreshSuccess, AuthStoreActions.ChangeTenantSuccess, AuthStoreActions.LoginMultiSuccess),
      switchMapTo(this._userCredentials$.pipe(take(1))),
      map((creds) => creds && EvoTokenParser(creds.accessToken, creds.permissions)),
      map((parsedToken) => AuthStoreActions.SetParsedToken({ parsedToken: parsedToken }))
    )
  );

  logoutRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.LogoutRequest),
      withLatestFrom(this._store$.select(AuthStoreSelectors.getAuthState)),
      switchMap(([action, state]) => {
        const logoutId = action.id == null || action.id == undefined ? state.selectedTokenId : action.id;
        //ensure user is is logged in
        if (state.ids.some((id) => id == logoutId)) {
          const userName: string = state.entities[state.selectedTokenId].userName;
          const logoutIds: string[] = Object.entries(state.entities).filter(([k, v]) => v.userName == userName).map(x => x[1].id)
          //if there is another user logged in then swap to them before logging out
          if (state.selectedTokenId == logoutId && state.ids.length > logoutIds.length) {
            return of(
              AuthStoreActions.LoginSelect({ id: (<string[]>state.ids).filter((id) => !logoutIds.includes(id))[0] }),
              AuthStoreActions.LogoutSuccess({ id: logoutId, ids: logoutIds.length ? logoutIds : null })
            );
          }
          else
            return of(AuthStoreActions.LogoutSuccess({ id: logoutId, ids: logoutIds, returnUrl: action.returnUrl }));
        } else {
          //User was not logged in, unable to logout
          return of(AuthStoreActions.LogoutFailure({ error: 'User not logged in' }));
        }
      }),
      share()
    )
  );

  logoutSuccessEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthStoreActions.LogoutSuccess),
        withLatestFrom(this._allTokens$, this._saveSession$),
        tap(([action, tokens, saveSession]) => {
          if (tokens.length == 0) {
            if (action.returnUrl) this._router.navigateByUrl(action.returnUrl);
            else this._router.navigate(['/']);
            this._authService.clearBrowserStorage();
          } else {
            if (saveSession) this._authService.setBrowserTokenStorage(tokens);
          }
        }),
        share()
      ),
    { dispatch: false }
  );

  logoutFailureEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthStoreActions.LogoutFailure),
        withLatestFrom(this._allTokens$, this._saveSession$),
        tap(([action, tokens, saveSession]) => {
          if (tokens.length == 0) {
            this._router.navigate(['/']);
            this._authService.clearBrowserStorage();
          } else {
            if (saveSession) this._authService.setBrowserTokenStorage(tokens);
          }
        }),
        share()
      ),
    { dispatch: false }
  );

  refreshRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.RefreshRequest),
      //exhaustMap should ignore any other refresh request while the http request is being processed
      //this is important as components will likely be trying to load asynchronously and wont know if another place ends up requesting the refresh
      exhaustMap((action) =>
        this._userClient.user_TokenRefresh(new RefreshTokenModel({ accessToken: action.accessToken, refreshToken: action.refreshToken })).pipe(
          map((result) =>
            AuthStoreActions.RefreshSuccess({
              id: action.id,
              userCredentials: <Partial<TokenDto>>{
                id: result.id,
                accessToken: result.accessToken,
                expiresOn: result.expiresOn,
                refreshToken: result.refreshToken,
                photo: result.photo,
              }
            })
          ),
          catchError((error) => of(AuthStoreActions.RefreshFailure({ id: action.id, error })))
        )
      ),
      //share prevents token refresh from being called multiple times if there are multiple http calls waiting
      share()
    )
  );

  //Remove token from session storage if refresh fails
  refreshFailureEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.RefreshFailure),
      switchMap((action) => of(AuthStoreActions.LogoutRequest({ id: action.id }))),
      share()
    )
  );

  //Update storage on user change
  loginSelectEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthStoreActions.LoginSelect),
        tap((action) => {
          let browserToken = this._authService.getBrowserTokenStorage();
          const tokens: TokenDto[] = browserToken[0].map((i) => TokenDto.fromJS(i));
          const token: TokenDto = tokens.find((x: any) => x.id == action.id);
          this._store$.dispatch(AuthStoreActions.LoginSelectSuccess({ token: token}));
          return this._authService.setBrowserSelectedTokenStorage(action.id)
        })
      ),
    { dispatch: false }
  );

  //Update token storage if a new photo is uploaded
  userStoreAddUpdatePhotoSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserStoreActions.AddPhotoSuccess, UserStoreActions.UpdatePhotoSuccess),
      withLatestFrom(this._store$.select(AuthStoreSelectors.getAuthState)),
      map(([action, state]) => {
        const ids = <string[]>state.ids;
        if (ids.includes(action.photo.userId)) return AuthStoreActions.UpdateToken({ id: action.photo.userId, changes: { photo: action.photo } });
      }),
      filter((action) => !!action)
    )
  );

  setTenentEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthStoreActions.SetTenant),
        tap((action) => this._authService.setBrowserTenantStorage({ name: action.tenantName, key: action.tenantKey, isPatient: action.isPatient })),
        share()
      ),
    { dispatch: false }
  );

  changeTenantEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthStoreActions.ChangeTenantRequest),
        exhaustMap((action) =>
          this._userCredentials$.pipe(
            take(1),
            switchMap(creds =>
              this._userClient.user_TokenRefresh(new RefreshTokenModel({ accessToken: creds.accessToken, refreshToken: creds.refreshToken })).pipe(
                map((result) => {
                  this._authService.setBrowserSelectedTokenStorage(result.id);
                  this._store$.dispatch(AuthStoreActions.SetTenant({ tenantName: action.tenantName, tenantKey: action.tenantKey, saveSession: action.saveSession }));
                  return AuthStoreActions.ChangeTenantSuccess({
                    id: result.id,
                    userCredentials: result
                  });
                }),
                catchError((error) => of(AuthStoreActions.ChangeTenantFailure({ id: creds.id, error })))
              )
            )
          )
        ),
        share()
      )
  );

  changeTenantSuccessEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        //When navigation request is made
        ofType(AuthStoreActions.ChangeTenantSuccess),
        withLatestFrom(this._userCredentials$),
        tap(([action, credentials]) => {
          //redirect from root (aka login page) if already logged in
          if (credentials && this._router.url !== "/dashboard/schedule") {
            this._router.navigate(['/dashboard']);
          }
        })
      ),
    { dispatch: false }
  );

  changeTenantFailureEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthStoreActions.ChangeTenantFailure),
      switchMap((action) => of(AuthStoreActions.LogoutRequest({ id: action.id }))),
      share()
    )
  );
}
