import { Component, Input, ViewChild, SimpleChanges, Output, EventEmitter, AfterViewInit, ElementRef, OnChanges } from '@angular/core';
import { FormControl, } from '@angular/forms';
import { startWith, map, debounceTime, filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject, fromEvent } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { RootStoreState, LocationsStoreSelectors } from 'src/app/root-store';
import { AdvancedSearchModel } from '../../dashboard/menu/advanced-search/advanced-search-dialog/advanced-search-dialog.component';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AutocompleteComponent implements OnChanges {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;
  @Input() options: any[];
  @Input() model: any;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() backgroundColor: string;
  @Input() errmsg: string = 'Select an value.';
  @Input() classList: string = 'native';
  @Input() onCreateOption: Function;
  @Input() panelWidth: string;
  @Input() smartSearch: boolean;
  @Input() required: boolean;
  @Input() patientSearch: boolean;
  @Input() autoSetValue: boolean = true;
  @Input() hideSearchIcon: boolean = false;
  @Input() isClearSearchBoxAfterSelect: boolean = false;
  @Input() incomingOptions: any[];
  @Input() isOptionBackground: boolean = false;
  @Input() disablePatientStatusFilter: boolean = false;
  @Input() isGlobalSearch: boolean = false;
  @Output() valueChange = new EventEmitter();
  @Output() onSearchChange = new EventEmitter();
  @Output() onToggleStatus = new EventEmitter();
  @Output() nextSearchPage = new EventEmitter();
  @Output() openAdvancedSearch = new EventEmitter();

  filteredOptions: Observable<any[]>;
  myControl = new FormControl();
  hasError: boolean = false;
  disabled: boolean = false;
  searchPageNumber: number = 0;
  searchStatusActive: boolean = true;
  panelHeight: any = 0;
  keyup: Observable<any>;
  private keyupSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _store$: Store<RootStoreState.State>
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.keyup = fromEvent(this.searchInput.nativeElement, 'keyup');
    this.keyup.pipe(
      takeUntil(this.keyupSubject),
      map((i: any) => [i.currentTarget.value, i.keyCode]),
      debounceTime(500)
    )
      .subscribe(keyupInfo => {
        let value: string = keyupInfo[0];
        let keyCode: number = keyupInfo[1];
        if (keyCode != 13) {
          this.searchPageNumber = 0;
          if (this.smartSearch && value.trim().length > 0) {
            this.onSearchChange.emit(value.trim());
          }

          if (this.options != null) {
            if (this.autoSetValue) {
              let option = this.options.find(option => option.name.trim().toLowerCase() == value.trim().toLowerCase());

              if (option != null) {
                this.myControl.setValue(option);
              } else {
                this.model = -1;
                this.valueChange.emit(this.model);
              }
            }
          }
        }
        else {
          let values = value.split(" ");
          let advancedSearchModel: AdvancedSearchModel = {
            LastName: values[0],
            FirstName: values[1]
          };
          this.openAdvancedSearch.emit(advancedSearchModel);
        }
      });

    this.virtualScroll.elementScrolled()
      .pipe(
        filter(event => this.virtualScroll.measureScrollOffset('bottom') < 5)
      )
      .subscribe(event => {
        if (this.searchPageNumber != 0 && (!this.incomingOptions || this.incomingOptions.length == 0))
          return;
        this.searchPageNumber += 1;
        this.nextSearchPage.emit(this.searchPageNumber);
      })
  }

  unsubscribeKeyUp(): void {
    this.keyupSubject.next(true);
  }

  subscribeKeyUp(): void {
    this.keyupSubject = new Subject<boolean>();
  }

  displayFn = (option: any) => {
    if (this.required) {
      if (option != null && option.id != this.model) {
        this.hasError = option.id > 0 ? false : true;
      }

      return option != null && option.name ? option.nickname ? `${option.name} *${option.nickname}` : option.name : this.searchInput.nativeElement.value;
    }

    return option != null && option.name ? option.name : this.searchInput.nativeElement.value;
  }

  emit(event: MatAutocompleteSelectedEvent) {
    if (!this.required && this.isClearSearchBoxAfterSelect) {
      this.searchInput.nativeElement.value = '';
      this.searchInput.nativeElement.blur();
    }

    if (event && event.option && event.option.value) {
      this.model = event.option.value.id;
      this.valueChange.emit(this.model);
    }

    if (!this.required && this.isClearSearchBoxAfterSelect) {
      this.myControl.setValue(null);
      this.options = [];
    }
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options != null) {
      let startValue: any;

      if (this.model != null) {
        startValue = this.options.find(option => option.id == this.model);

        if (startValue != null) {
          this.setFilteredOptions(startValue.name);
          this.myControl.setValue(startValue);
        } else {
          this.setFilteredOptions();
        }
      } else {
        this.setFilteredOptions();
      }
    } else {
      this.myControl.setValue(null);
    }

    if (changes.incomingOptions && this.incomingOptions && this.incomingOptions.length > 0) {
      let previousLength: number = this.options.length;
      this.options = _.concat(this.options, this.incomingOptions);
      this.setFilteredOptions();
      setTimeout(() => {
        this.virtualScroll.scrollToIndex(previousLength, "smooth");
      });
    }
  }

  setFilteredOptions(defaultVal: string = '') {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(defaultVal),
      map(value => typeof value === 'string' ? value : value != null ? value.name : null),
      map(name => name ? this._filter(name) : this.options.slice())
    );

    this.filteredOptions.subscribe(resp => {
      this.panelHeight = resp.length > 0 ? (63 * resp.length) + 'px' : '200px';
    })

  }

  displayAllOptions() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value != null ? value.name : null),
      map(name => name ? this._filter(name) : this.options.slice())
    );

    this.filteredOptions.subscribe(resp => {
      this.panelHeight = resp.length > 0 ? (65 * resp.length) + 'px' : '200px';
    })

    setTimeout(() => { this.searchInput.nativeElement.focus(); });
  }

  showErrorMessage(message?: string) {
    if (message) { this.errmsg = message; }
    this.hasError = true;
  }

  hideErrorMessage() {
    this.hasError = false;
  }

  clear() {
    this.hideErrorMessage();
    this.searchInput.nativeElement.value = '';
    this.model = null;
    this.valueChange.emit(this.model);
  }

  getPatientLocation(locationId) {
    return this._store$.select(LocationsStoreSelectors.selectLocationById(locationId));
  }

  toggleSearchStatus(): void {
    this.searchStatusActive = !this.searchStatusActive;
    this.onToggleStatus.emit(this.searchStatusActive);
    setTimeout(() => { this.searchInput.nativeElement.focus(); });
  }
}
