import { Component, OnInit, ViewChild, TemplateRef, Inject, EventEmitter, Output, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MessageBuilderChannelTypeEnum } from 'src/app/patient-details/communications/message-builder/models';
import { Store, ActionsSubject } from '@ngrx/store';
import {
  PatientMessageStoreSelectors,
  PatientsStoreSelectors,
  PatientStoreEntity,
  ResponsiblePartyStoreSelectors,
  RootStoreState,
  MessageDraftStoreSelectors,
  InternalUserStoreSelectors,
  LocationsStoreSelectors,
  PatientMessageStoreActions,
  MessageDraftStoreActions,
  LocationStoreEntity,
  PatientsStoreActions,
  ResponsiblePartyStoreEntity
} from 'src/app/root-store';
import { CommuncationDeskStoreSelectors } from 'src/app/root-store/communication-desk-store';
import { faUndo, faCheckCircle, faSortDown } from '@fortawesome/free-solid-svg-icons';
import {
  MessageDto,
  PatientClient,
  DocumentTemplateDto,
  StationaryDto,
  StorageItemBaseDto,
  CreateMessageDto,
  MessageChannelTypeEnum,
  RecipientDto,
  AttachmentDto,
  MessageAttachmentDto,
  FileParameter,
  UserEmailAddressAvailabilityDto,
  UserClient,
  SecureAccessDto,
  DentistClient,
  ClinicClient,
  MessageDraftDto,
  PatientReferralDto,
  DirectionEnum,
  StorageContentTypeEnum
} from 'src/app/shared/services/api.service';
import { MessageBuilderService } from 'src/app/patient-details/communications/message-builder/message-builder.service';
import { combineLatest, Observable, Subject, of, forkJoin, from } from 'rxjs';
import { catchError, defaultIfEmpty, distinctUntilChanged, filter, map, mergeAll, switchMap, take, takeUntil, tap, toArray, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { PatientFilesSelectService } from 'src/app/shared-module/patient-files-select/patient-files-select.component';
import { MatBottomSheet, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ClockFaceTime } from 'ngx-material-timepicker/src/app/material-timepicker/models/clock-face-time.interface';
import { TimePeriod } from 'ngx-material-timepicker/src/app/material-timepicker/models/time-period.enum';
//import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IMessageBuilderRecipient, IPatientCommunicationMessage, MessageBuilderComponent, PatientCommunicationMessage } from 'src/app/patient-details/communications/message-builder';
import { HttpClient } from '@angular/common/http';
import { CARD_DATA, ICardData } from 'src/app/shared/models';
import { ofType } from '@ngrx/effects';
import { MessageServiceEventEnum } from '../../../../core/message.service';
import html2canvas from 'html2canvas';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { FolderTreeViewSelectComponent, FolderTreeViewSelectModel } from 'src/app/patient-details/folder-tree-view-select/folder-tree-view-select.component';
import html2pdf from 'html2pdf.js';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageBuilderActions, ImageBuilderComponent, ImageBuilderHeading } from '../../../../patient-details/communications/message-builder/image-builder/image-builder.component';
import { FIELD_MERGE, FIELD_MERGE_END_CHAR, FIELD_MERGE_ITEM, FIELD_MERGE_START_CHAR } from '../../../../shared/constants/field-merge-keys.constants';
import { PatientTreatmentSelectorDialogComponent } from '../../../patient-treatment-selector/patient-treatment-selector-dialog.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DialogConfirmTemplateComponent } from '@shared/click-confirm/dialog-confirm-template/dialog-confirm-template.component';

@Component({
  selector: 'app-refer-out-back',
  templateUrl: './refer-out-back.component.html',
  styleUrls: ['./refer-out-back.component.scss']
})
export class ReferOutBackComponent implements OnInit {

  @ViewChild('draftOptionDialog', { static: false }) draftOptionDialog: TemplateRef<any>;
  @ViewChild('sendLaterDialog', { static: true }) sendLaterDialog: TemplateRef<any>;
  @ViewChild('unsecureWarningDialog', { static: false }) unsecureWarningDialog: TemplateRef<any>;
  @ViewChild('mobileLoginWarningDialog', { static: false }) mobileLoginWarningDialog: TemplateRef<any>;
  @ViewChild('mailcontent', {static: false}) mailContent: ElementRef;
  @ViewChild('mailfootercontent', {static: false}) mailfootercontent:ElementRef;

  faUndo = faUndo;
  faCheckCircle = faCheckCircle;
  faSortDown = faSortDown;

  professionalRecipientSearchFormControl: FormControl = new FormControl();
  professionalCcSearchFormControl: FormControl = new FormControl();
  messageFormGroup: FormGroup;
  desks$ = this._store$.select(CommuncationDeskStoreSelectors.selectAllDesks);
  templates: DocumentTemplateDto[];
  templates$: Observable<DocumentTemplateDto[]> = this._messageBuilderService.templates$.pipe(map((templates: DocumentTemplateDto[]) => {
    this.templates = _.orderBy(templates, (t => t.name.toLowerCase()), 'asc');
    return this.templates;
  }));

  stationaries$: Observable<StationaryDto[]> = this._messageBuilderService.stationary$.pipe(map((stationaries: StationaryDto[]) => {
    return _.orderBy(stationaries, (s => s.name.toLowerCase()), 'asc');
  }));

  selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  selectedPatientId$: Observable<number> = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  private selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  selectedLocation: LocationStoreEntity;
  @Output('templateNameChanged') templateNameChanged: EventEmitter<string> = new EventEmitter<string>();
  responsibleParties$: Observable<ResponsiblePartyStoreEntity[]> = this._store$.select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties);
  responsibleParties: ResponsiblePartyStoreEntity[] = [];
  selectedPatientTreatmentId: number;
  fieldMergeDiagnosisTxGoal: FIELD_MERGE_ITEM = _.find(FIELD_MERGE, { 'name': 'diagnosisTxGoal' });

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '528px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'header',
    defaultParagraphSeparator: '',
    sanitize: false,
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',

        'underline',
        'strikeThrough',
        'subscript',
        'superscript',

        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'customClasses',
        'textColor',
        'backgroundColor',
        'link',
        'unlink',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  };

  isGeneratingTemplateBody: boolean = false;
  isGeneratingStationary: boolean = false;
  localFileAttachments = [];
  isDraftLoading: boolean;
  isSending: boolean;
  isWorkingMessageDraft$ = this._store$.select(MessageDraftStoreSelectors.selectMessageDraftsIsLoading);
  draftPopupOption: any = {
    color: null,
    user: null
  };
  draftColorBox: any[] = [
    { color: '#F11212', selected: false },
    { color: '#318ED0', selected: false },
    { color: '#F9D069', selected: false },
    { color: '#C4C4C4', selected: false },
    { color: '#000000', selected: false }
  ];
  dialogRef: MatDialogRef<any>;

  users$: Observable<{ value: string; label: string }[]> = this._store$.select(InternalUserStoreSelectors.selectAllInternalUsers)
    .pipe(map((users) => users.filter(users => users.isActive && users.userType == 'Internal').map((users) => ({ value: users.id, label: `${users.firstName} ${users.lastName}` }))));

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  sendNowMessage: IPatientCommunicationMessage = { isSecure: true };

  isFutureDate: (date: Date, hour: number, minute: number, ampm: TimePeriod) => boolean = (date, hour, minute, ampm) =>
    moment(date).isValid() &&
    moment(date)
      .startOf('day')
      .set('hour', hour + (ampm == 'PM' ? 12 : 0))
      .set('minute', minute)
      .isAfter(new Date());

  receiptientType: any = 'dentist';
  ccType: any = 'dentist';
  rxRequestContent: any;
  dialog: DialogComponent;
  genrtMailPdf: boolean = false;
  patientImages: ImageBuilderHeading[] = [];

  templateEmails:DocumentTemplateDto[] = [];
  filteredTemplateEmails:DocumentTemplateDto[] = [];
  @ViewChild('templateEmailInput', {static: false}) templateEmailInput:ElementRef;
  private _overlayRef: OverlayRef;
  private _componentPortal: ComponentPortal<DialogConfirmTemplateComponent> = new ComponentPortal(DialogConfirmTemplateComponent);

  constructor(
    private _fb: FormBuilder,
    private _store$: Store<RootStoreState.State>,
    private _messageBuilderService: MessageBuilderService,
    private _patientClient: PatientClient,
    private _patientFilesSelect: PatientFilesSelectService,
    private _snackbar: MatSnackBar,
    private matDialog: MatDialog,
    private httpClient: HttpClient,
    private _userClient: UserClient,
    private _dentistClient: DentistClient,
    private _clinicClient: ClinicClient,
    @Inject(CARD_DATA) private data: ICardData,
    private _actions$: ActionsSubject,
    private sanitizer: DomSanitizer,
    private _bottomSheet: MatBottomSheet,
    private _overlay: Overlay
  ) {
    this.dialog = new DialogComponent(this.matDialog);
    this._overlayRef = this._overlay.create({
      positionStrategy: this._overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      minWidth: 'fit-content',
      hasBackdrop: true,
    });
  }

  ngOnInit() {
    this.initializeFormGroup();

    this.selectedLocation$
      .pipe(
        takeUntil(this._destroy$),
        filter((id) => !!id)
      )
      .subscribe((selectedLocation) => {
        this.selectedLocation = selectedLocation;
      });

    this.data.incoming
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(resp => {
        this.data.action.emit({ activeSide: 'back', actionName: '' });
        if (!resp) {
          this.initializeFormGroup();
          this.localFileAttachments = [];
          this.patientImages = [];
          return;
        }

        if (resp.event == MessageServiceEventEnum.attachImage) {
          let attachedFiles = this.getAttachedFiles();
          if (!attachedFiles) attachedFiles = [];
          attachedFiles.push(resp.image)
          this.messageFormGroup.get('fileAttachments').setValue(attachedFiles);
        }
        else {
          this.rxRequestContent = resp;

          if (resp.dentistId) {
            this._dentistClient.dentist_GetDentist(resp.dentistId)
              .pipe(takeUntil(this._destroy$))
              .subscribe(dentist => {

                let dentistValue = {
                  id: dentist.id,
                  phoneEmail: dentist.email,
                  phone: dentist.mobileNumber,
                  email: dentist.email,
                  name: `${dentist.firstName} ${dentist.lastName}`,
                  type: 'dentist'
                }
                this.setRecipientType('dentist');
                this.messageFormGroup.get('recipient').patchValue(dentistValue);
                this.professionalRecipientSearchFormControl.setValue(dentistValue);
              })
          }

          this.messageFormGroup.patchValue({
            templateId: resp.templateId,
            stationaryId: resp.stationaryId,
            messageBody: resp.editorContent.innerHTML,
            templateContent: resp.template.content
          })
        }
      })

    this.responsibleParties$
      .pipe(
        takeUntil(this._destroy$),
        filter(respParty => !!respParty)
      )
      .subscribe(responsibleParties => {
        this.responsibleParties = responsibleParties.filter(responsibleParty => responsibleParty.primaryEmail);

        console.log("responsibleParties: ", this.responsibleParties);
      })

    this.templates$.pipe(filter(templates => !!templates)).subscribe(templates => {
      this.templateEmails = templates;
      this.filteredTemplateEmails = templates;
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    if (this._overlayRef && this._overlayRef.hasAttached) this._overlayRef.detach();
  }

  initializeFormGroup(): void {
    this.messageFormGroup = this._fb.group({
      messageDraftId: [],
      messageDraftETag: [],
      channelType: [MessageBuilderChannelTypeEnum.Email],
      recipient: this._fb.group({
        id: [],
        phoneEmail: [null, [Validators.required]],
        phone: [],
        email: [],
        name: [],
        type: [],
        isSecureAccess: [],
        notificationId: []
      }),
      responsiblePartyId: [],
      templateId: [],
      formTypeId: [],
      formTypeToken: [],
      communicationDeskId: [],
      cc: this._fb.group({
        id: [],
        contactId: [],
        phoneEmail: [],
        name: [],
        type: [],
        isSecureAccess: [],
        email: [],
      }),
      repeat: this._fb.group({
        startDate: [null, [Validators.required]],
        time: ['01:00 pm'],
        repeatCount: [null, [Validators.required, Validators.min(1), Validators.max(30)]],
        repeatDayDelays: [null, [Validators.required, Validators.min(1), Validators.max(30)]],
      }),
      subject: [],
      messageBody: [],
      templateContent: [],
      followUpDate: [],
      fileAttachments: [],
      localFileAttachments: [],
      stationaryId: [],
      stationaryHeader: [],
      stationaryFooter: [],
      isSecured: [true]
    });
  }

  initializePatientImage(content: string): string {
    this.patientImages = [];
    if (!content) return null;
    let patientImageCount: number = 0;

    while (content.search('{PatImage}') > -1) {
      content = content.replace('{PatImage}',
        `<div style="display:inline-block;"><img style="border-style:solid; width:100px; height:100px;" src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="" data-patient-image-count="${patientImageCount}" /></div>`);
      this.patientImages.push({
        id: patientImageCount,
        image: null,
        defaultImage: "/assets/treatment-card/no-photo.png",
        width: 100,
        height: 100
      });
      patientImageCount++;
    }

    return content;
  }

  openImageBuilder() {
    const dialogRef = this.matDialog.open(
      ImageBuilderComponent,
      {
        data: this.patientImages,
        disableClose: true
      }
    );

    dialogRef.afterClosed()
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        if (result && result.action) {
          switch (result.action) {
            case ImageBuilderActions.Save: {
              this.isGeneratingTemplateBody = true;
              this.patientImages = result.images;
              this.patientImages.forEach((patientImage: ImageBuilderHeading) => {
                this.setImage(patientImage);
              });
              this.generateMessageBody(null);
              this.isGeneratingTemplateBody = false;
              break;
            }
            case ImageBuilderActions.Cancel: {
              break;
            }
          }
        }
      });
  }

  private setImage(patientImage: ImageBuilderHeading): Promise<void> {
    if (!patientImage.imageBase64 || patientImage.id < 0) return;
    let templateContent: string = this.messageFormGroup.get('templateContent').value;

    let keyIndex: number = templateContent.indexOf(`data-patient-image-count="${patientImage.id}"`);
    if (keyIndex < 0) return;

    let startIndex: number = templateContent.lastIndexOf("<img", keyIndex);
    let endIndex: number = templateContent.indexOf("/>", keyIndex);
    if (startIndex < 0 || endIndex < 0) return;

    let newImage: string = `<img style="width:${patientImage.width}px;height:${patientImage.height}px;max-width:100%;max-height:100%;display:block;" src="${patientImage.imageBase64}" alt="" data-patient-image-count="${patientImage.id}" title="data-patient-image-title-${patientImage.id}" />`;

    if (!patientImage.image) {
      newImage = `<img style="border-style:solid; width:100px; height:100px;" src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="" data-patient-image-count="${patientImage.id}" />`;
    }

    let content: string = templateContent.substring(0, startIndex) + newImage + templateContent.substring(endIndex + 2);
    this.messageFormGroup.get('templateContent').setValue(content);
  }

  getDentistSerarchValue(value, isCc?: boolean) {
    let dentist: any = value.dentist;
    let clinic: any = value.clinic;

    if (dentist.email == null && clinic) {
      dentist.email = clinic.email
    }

    if (dentist.mobileNumber == null && clinic) {
      dentist.mobileNumber = clinic.phoneNumber
    }

    if (!dentist.phone)
      dentist.phone = dentist.mobileNumber;

    dentist.name = `${dentist.firstName} ${dentist.lastName}`;
    dentist.type = 'dentist';

    if (isCc) {
      this.setCcType('dentist');
      this.professionalCcSearchFormControl.setValue(dentist);
      this.ccChange(dentist);
    }
    else {
      this.setRecipientType('dentist');
      this.professionalRecipientSearchFormControl.setValue(dentist);
      this.recipientChange(dentist);
    }
  }

  clinicSelected(clinic: any, isCc?: boolean): void {
    let selectedClinic = {
      name: clinic.name,
      email: clinic.email,
      type: 'clinic',
      id: clinic.id,
      isSecureAccess: clinic.isSecureAccess
    };

    if (isCc) {
      this.professionalCcSearchFormControl.setValue(selectedClinic);
      this.ccChange(selectedClinic);
    }
    else {
      this.professionalRecipientSearchFormControl.setValue(selectedClinic);
      this.recipientChange(selectedClinic);
    }
  }

  getCaretPos(oField) { }

  recipientChange(value: any): void {
    if (!value) return;
    value.phoneEmail = value.email;
    this.messageFormGroup.get('recipient').patchValue(value);
    this.generateTemplateBody();
    this.generateStationary();
  }

  ccChange(value: any): void {
    if (!value) return;
    value.phoneEmail = value.email;
    this.messageFormGroup.get('cc').patchValue(value);
    this.generateTemplateBody();
    this.generateStationary();
  }

  isDiagnosisTxGoalHasMailMergeContent(content: string): boolean {
    if (!content) return false;
    let isDiagnosisTxGoalContent: boolean = false;
    _.each(this.fieldMergeDiagnosisTxGoal.keys, (key: string) => {
      let mailMergeKey = `${FIELD_MERGE_START_CHAR}${key}${FIELD_MERGE_END_CHAR}`;
      if (content.indexOf(mailMergeKey) !== -1) {
        isDiagnosisTxGoalContent = true;
        return;
      }
    });

    return isDiagnosisTxGoalContent;
  }

  readyToGenerate(message: any, patientId: number): void {
    let templateId: number = message.templateId;

    this.isGeneratingTemplateBody = true;
    let contactId = null;
    if (message.recipient)
      contactId = message.recipient.id;

    let professionalId: number = 0;
    if (message.recipient && message.recipient.type == 'dentist')
      professionalId = message.recipient.id;
    else if (message.cc && message.cc.type == 'dentist')
      professionalId = message.cc.id;

    let clinicId: number = 0;
    if (message.recipient && message.recipient.type == 'clinic')
      clinicId = message.recipient.id;
    else if (message.cc && message.cc.type == 'clinic')
      clinicId = message.cc.id;

    this._patientClient.patient_GetPatientTemplate(
      patientId,
      templateId,
      contactId,
      null,
      null,
      professionalId,
      message.formTypeId,
      message.formTypeToken,
      null,
      clinicId,
      null,
      message.channelType != MessageBuilderChannelTypeEnum.SMS,
      this.selectedPatientTreatmentId
    )
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((res: DocumentTemplateDto) => {
        let initializedContent: string = this.initializePatientImage(res ? res.content : null);
        this.messageFormGroup.get('templateContent').setValue(initializedContent);

        if((message.channelType == MessageBuilderChannelTypeEnum.Email || message.channelType == MessageBuilderChannelTypeEnum.SMS) && !message.communicationDeskId && res.defaultCommunicationDeskId){
          this.messageFormGroup.get('communicationDeskId').setValue(res.defaultCommunicationDeskId);
        }
  
        if((message.channelType == MessageBuilderChannelTypeEnum.Email) && !message.subject && res.defaultSubject){
          this.messageFormGroup.get('subject').setValue(res.defaultSubject);
        }

        if ((message.channelType == MessageBuilderChannelTypeEnum.Email || message.channelType == MessageBuilderChannelTypeEnum.SnailMail) &&
          !message.stationaryId &&
          res.defaultStationaryId) {
          this.messageFormGroup.get('stationaryId').setValue(res.defaultStationaryId);
          this.generateStationary();
        }

        else
          this.generateMessageBody();

        this.isGeneratingTemplateBody = false;
        this.templateNameChanged.next(res.name);
      }, (err) => {
        console.log('template error:', err);
        this.isGeneratingTemplateBody = false;
      });
  }

  openPatientTreatmentSelector(): Promise<boolean> {
    this.selectedPatientTreatmentId = null;
    return new Promise(resolve => {
      this._bottomSheet
        .open<PatientTreatmentSelectorDialogComponent>(PatientTreatmentSelectorDialogComponent, {})
        .afterDismissed()
        .pipe(
          take(1),
          takeUntil(this._destroy$)
        )
        .subscribe((result) => {
          this.selectedPatientTreatmentId = result;
          resolve(true);
        })
    });
  }

  generateTemplateBody(): void {
    this.selectedPatient$
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((patient) => {
        let message = this.messageFormGroup.value;
        let templateId: number = message.templateId;
        if (!message || !templateId) return;
        let selectedTemplate: DocumentTemplateDto = _.find(this.templates, { 'id': templateId });

        if (selectedTemplate && this.isDiagnosisTxGoalHasMailMergeContent(selectedTemplate.content)) {
          this.openPatientTreatmentSelector().then(() => {
            this.readyToGenerate(message, patient.id);
          });
        }
        else {
          this.readyToGenerate(message, patient.id);
        }
      });
  }

  generateStationary() {
    this.selectedPatient$
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((patient) => {
        let message = this.messageFormGroup.value;
        if (!message || !message.stationaryId) return;

        this.isGeneratingStationary = true;
        let contactId = null;
        let stationaryId = message.stationaryId;
        if (message.recipient)
          contactId = message.recipient.id;

        this._patientClient.patient_GetPatientStationary(
          patient.id,
          stationaryId,
          contactId
        ).subscribe((res: StationaryDto) => {
          this.messageFormGroup.patchValue({
            'stationaryHeader': res.header,
            'stationaryFooter': res.footer
          })
          this.generateMessageBody();

          this.isGeneratingStationary = false;
        }, (err) => {
          console.log('stationary error:', err);
          this.isGeneratingStationary = false;
        });
      });
  }

  generateMessageBody(copiedMessage?: string, isSkip?: boolean): void {
    this.confirmGenerateMessage(isSkip).then((confirmed: boolean) => {

      console.log(confirmed)

      if (confirmed) {
        let message = this.messageFormGroup.value;
        let messageBody: string = message.templateContent ? message.templateContent : '';
        let _splitedContent1: any;
        let _splitedContent2: any;

        if (message.stationaryHeader) {
          messageBody = `${message.stationaryHeader}<div>${messageBody}</div>`;
        }

        if (this.rxRequestContent && this.rxRequestContent.isRxRequest) {
          let rxRequestIndx: any = messageBody.indexOf('{RxRequest}');
          if (rxRequestIndx != -1) {
            _splitedContent1 = messageBody.substring(0, rxRequestIndx);
            _splitedContent2 = messageBody.substring(rxRequestIndx + 11, messageBody.length);
            messageBody = `${_splitedContent1} <br><br> ${this.rxRequestContent.rxRequestElement} <br><br> ${_splitedContent2}`;
          }
        }

        if (copiedMessage) {
          messageBody = `${messageBody}<br><br>${copiedMessage}`;
        }

        if (message.stationaryFooter) {
          messageBody = `${messageBody}<div>${message.stationaryFooter}</div>`;
        }

        messageBody = messageBody
          .replace(new RegExp(' class="mailmerge-span"', 'g'), '')
          .replace(new RegExp('\n', 'g'), '<br>')
          .replace(new RegExp('\{.*?\}', 'g'), '');

        this.messageFormGroup.get('messageBody').setValue(messageBody);
      }
    })

  }

  getAttachedFiles(): StorageItemBaseDto[] {
    return this.messageFormGroup.get('fileAttachments').value as StorageItemBaseDto[];
  }

  getAttachedLocalFiles(): File[] {
    return this.messageFormGroup.get('localFileAttachments').value as File[];
  }

  removeAttachment(file: StorageItemBaseDto) {
    let attachments = this.getAttachedFiles();
    const ind = attachments.indexOf(file);
    if (ind > -1) attachments.splice(ind, 1);
    this.messageFormGroup.get('fileAttachments').setValue(attachments);
  }

  removeLocalFileAttachment(i: number): void {
    let attachments = this.getAttachedLocalFiles();
    if (!attachments || attachments.length < 1) return;
    if (i > -1) {
      attachments.splice(i, 1);
      this.localFileAttachments.splice(i, 1);
    }
    this.messageFormGroup.get('localFileAttachments').setValue(attachments);
  }

  openFileSelector() {
    this.selectedPatient$
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((patient) =>
        this._patientFilesSelect
          .open()
          .pipe(
            takeUntil(this._destroy$),
            take(1)
          )
          .subscribe((files) => {
            if (files) {
              let attachedFiles = this.getAttachedFiles();
              if (attachedFiles) {
                attachedFiles.push(...files)
                this.messageFormGroup.get('fileAttachments').setValue(attachedFiles);
              } else {
                this.messageFormGroup.get('fileAttachments').setValue([...files]);
              }
            }
          })
      );
  }

  localFileAttachmentChange(event): void {
    this.messageFormGroup.get('localFileAttachments').setValue([...event]);
  }

  OpenDraftPopup() {
    this.draftPopupOption = {
      color: null,
      user: null,
      comments: ''
    };

    this.draftColorBox.map(item => {
      item.selected = false;
    })

    this.dialogRef = this.matDialog.open(this.draftOptionDialog);
    this.dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe(dialogResponse => {
        if (dialogResponse) {
          this.saveEmailDraft();
        }
      })

  }

  createSendLater() {

    this.dialogRef = this.matDialog.open(this.sendLaterDialog);
    this.dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this._destroy$),
        take(1)
      )
      .subscribe((result: { date: Date; hour: Observable<ClockFaceTime>; minute: Observable<ClockFaceTime>; ampm: TimePeriod }) => {
        if (result && moment(result.date).isValid()) {
          combineLatest([result.hour, result.minute])
            .pipe(take(1))
            .subscribe(([hour, minute]) => {
              let sendDelayDate = moment(result.date);
              sendDelayDate.hour(hour.time + ((result.ampm == 'PM' && hour.time < 12) ? 12 : 0));
              sendDelayDate.minute(minute.time);

              let sendNowMessage = this.messageFormGroup.value;

              sendNowMessage.isSecured ?
                this.secureSendNow(sendDelayDate.toDate()) :
                this.unsecureSendNow(sendDelayDate.toDate());
            });
        }
      });
  }

  toggleDraftColor(ind) {
    this.draftColorBox.map((item, i) => {
      if (i != ind) {
        item.selected = false;
      }
    })
    this.draftColorBox[ind].selected = this.draftColorBox[ind].selected == true ? false : true;
    if (this.draftColorBox[ind].selected) {
      this.draftPopupOption.color = this.draftColorBox[ind].color;
    }
  }

  unsecureSendNow(delaySendDate?: Date) {

    let sendNowMessage = this.messageFormGroup.value;

    if (sendNowMessage.channelType == 'Email') {
      this.dialogRef = this.matDialog.open(this.unsecureWarningDialog);
      this.dialogRef.afterClosed()
        .pipe(
          takeUntil(this._destroy$),
          take(1)
        )
        .subscribe(dialogResponse => {
          if (dialogResponse) {
            this.createSendNow(delaySendDate);
          }
          else {
            this.isSending = false;
          }
        })
    }
    else {
      this.createSendNow(delaySendDate);
    }
  }

  createSendNow(delaySendDate?: Date) {
    this.isSending = true;

    let sendNowMessage = this.messageFormGroup.value;

    this.selectedPatientId$
      .pipe(
        take(1),
        switchMap((patientId) =>
          combineLatest([
            of(patientId),
            ((sendNowMessage.fileAttachments && sendNowMessage.fileAttachments.length > 0)
              || (sendNowMessage.localFileAttachments && sendNowMessage.localFileAttachments.length > 0)) ?
              this.postAttachment(
                patientId,
                sendNowMessage.fileAttachments,
                sendNowMessage.localFileAttachments
              ) :
              of(null),
          ])
        ),
        map(([patientId, attachments]) => {
          //ensure responsible party has contact point
          if (!sendNowMessage.recipient || !sendNowMessage.recipient.phoneEmail) {
            this._snackbar.open(
              `Please select a contact with ${sendNowMessage.channelType == 'Email' ? 'an email address' : 'a cell phone number'}`,
              'OK'
            );
            return;
          }

          //create message
          let messageDto: CreateMessageDto = new CreateMessageDto({
            patientId: patientId,
            channelType: sendNowMessage.channelType == 'Email' ? MessageChannelTypeEnum.Email : MessageChannelTypeEnum.SMS,
            isHTML: false,
            toRecipients: [
              {
                contactId: sendNowMessage.recipient.type == 'contact' ? sendNowMessage.recipient.id : null,
                fullName: sendNowMessage.recipient.name,
                phoneOrEmail: sendNowMessage.recipient.phoneEmail,
              },
            ],
            ccRecipients: sendNowMessage.cc && sendNowMessage.cc.phoneEmail ?
              [
                new RecipientDto({
                  contactId: sendNowMessage.cc.contactId,
                  phoneOrEmail: sendNowMessage.cc.phoneEmail,
                  fullName: sendNowMessage.cc.name,
                }),
              ] : null,
            subject: sendNowMessage.channelType == 'Email' && sendNowMessage.subject,
            textBody: sendNowMessage.messageBody,
            communicationDeskId: sendNowMessage.communicationDeskId,
            formTypeId: sendNowMessage.formTypeId,
            formTypeToken: sendNowMessage.formTypeToken,
            locationId: this.selectedLocation.id,
            isSecured: sendNowMessage.isSecured
          });

          if (delaySendDate) messageDto.delaySendDate = delaySendDate;

          if (sendNowMessage.channelType == 'Email') {
            messageDto.htmlBody = messageDto.textBody.replace(new RegExp('\r?\n', 'g'), '<br />');
            messageDto.textBody = null;
            messageDto.isHTML = true;

            if (attachments && attachments.length > 0) {
              messageDto.messageAttachments = _.map(attachments, (attachment: AttachmentDto) => {
                let messageAttachmentDto: MessageAttachmentDto = new MessageAttachmentDto({
                  id: 0,
                  messageId: 0,
                  storageItemId: attachment.storageItemId,
                  name: attachment.name,
                  locationUrl: attachment.locationUrl,
                  contentType: attachment.contentType,
                  createdWhen: null,
                  createdBy: null,
                  isDeleted: false
                });
                return messageAttachmentDto;
              });
            }
          }

          let patientReferralDto: PatientReferralDto = new PatientReferralDto();
          patientReferralDto.patientId = patientId;
          patientReferralDto.referralDate = moment().tz(this.selectedLocation.ianaTimeZone).toDate();
          patientReferralDto.direction = DirectionEnum.Inbound;

          messageDto.patientReferral = patientReferralDto;


          return messageDto;
        })
      )
      //send message
      .subscribe((messageDto) => {
        if (messageDto) {

          this._actions$
            .pipe(
              ofType(PatientMessageStoreActions.AddMessageSuccess),
              take(1),
              takeUntil(this._destroy$)
            ) //listen for success then flip
            .subscribe((result) => {
              this.isSending = false;
              this.backToFront();
            });

          this._store$.dispatch(PatientMessageStoreActions.AddMessageRequest({
            message: messageDto as CreateMessageDto,
            messageDraftId: sendNowMessage.messageDraftId
          }));


        }

      });
  }

  postAttachment(
    patientId: number,
    fileAttachments: any[],
    localFileAttachments: File[]
  ): Promise<AttachmentDto[]> {
    let fileAttachments$ = [];
    _.each(fileAttachments, (f: any) => {
      fileAttachments$.push(this.httpClient.get(f.locationUrl, { responseType: 'blob' }));
    });

    return new Promise(resolve => {
      forkJoin(...fileAttachments$)
        .pipe(
          defaultIfEmpty(null),
          take(1),
          takeUntil(this._destroy$),
          switchMap((fileBlobs) => {
            let files: FileParameter[] = [];

            _.each(fileBlobs, (f: Blob, i: number) => {
              const data = new Blob([f], { type: f.type })
              files.push({
                data,
                fileName: fileAttachments[i].name.replace(new RegExp('/', 'g'), '_'),
              });
            });

            _.each(localFileAttachments, (f: File, i: number) => {
              const data = new Blob([f], { type: f.type })
              files.push({
                data,
                fileName: f.name.replace(new RegExp('/', 'g'), '_'),
              });
            });

            return of(files);
          }),
          map((files) => {
            return files;
          })
        )
        .subscribe((files) => {

          let sendNowMessage = this.messageFormGroup.value;

          this._patientClient.patient_PostCreateAttachment(
            patientId,
            files
          ).pipe(
            take(1),
            takeUntil(this._destroy$)
          )
            .subscribe((res) => {
              resolve(res);
            });
        });
    });
  }

  secureSendNow(delaySendDate?: Date) {
    let unSecureAccesses = [];
    let sendNowMessage = this.messageFormGroup.value;

    if (((sendNowMessage.fileAttachments && sendNowMessage.fileAttachments.length > 0) ||
      (sendNowMessage.localFileAttachments && sendNowMessage.localFileAttachments.length > 0))
    ) {
      if (sendNowMessage.recipient.id > 0 && !sendNowMessage.recipient.isSecureAccess) {
        unSecureAccesses.push({
          id: sendNowMessage.recipient.id,
          type: sendNowMessage.recipient.type
        });
      }

      if (sendNowMessage.cc.id > 0 && !sendNowMessage.cc.isSecureAccess) {
        unSecureAccesses.push({
          id: sendNowMessage.cc.id,
          type: sendNowMessage.cc.type
        });
      }

      let otherEmails = [];
      if (sendNowMessage.recipient &&
        !sendNowMessage.recipient.type &&
        sendNowMessage.recipient.phoneEmail &&
        this.validEmail(sendNowMessage.recipient.phoneEmail)) {
        otherEmails.push(sendNowMessage.recipient.phoneEmail)
      }

      if (sendNowMessage.cc &&
        !sendNowMessage.cc.type &&
        sendNowMessage.cc.phoneEmail &&
        this.validEmail(sendNowMessage.cc.phoneEmail)) {
        otherEmails.push(sendNowMessage.cc.phoneEmail)
      }

      if (otherEmails.length > 0) {
        let checkUnsecureObservables = this.checkUnsecureOtherEmailObservables(otherEmails);
        from(checkUnsecureObservables)
          .pipe(
            mergeAll(1),
            toArray(),
            take(1),
            takeUntil(this._destroy$),
            catchError((error) => {
              this._snackbar.open(error.message, 'Ok', { duration: 3000 });
              this.isSending = false;
              return null;
            })
          )
          .subscribe((userEmails: UserEmailAddressAvailabilityDto[]) => {
            _.each(userEmails, (userEmail: UserEmailAddressAvailabilityDto) => {
              if (userEmail.isAvailable)
                unSecureAccesses.push({
                  type: 'other',
                  email: userEmail.emailAddress
                });
            });

            this.checkUnSecureAccess(unSecureAccesses, delaySendDate);
          });
      }
      else
        this.checkUnSecureAccess(unSecureAccesses, delaySendDate);
    }
    else {
      this.createSendNow(delaySendDate);
    }
  }

  validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkUnsecureOtherEmailObservables(emails: string[]): Observable<UserEmailAddressAvailabilityDto>[] {
    let checkUnsecureOtherEmails: Observable<UserEmailAddressAvailabilityDto>[] = [];

    _.each(emails, (email: string) => {
      checkUnsecureOtherEmails.push(this._userClient.user_CheckEmailAvailability(email));
    });

    return checkUnsecureOtherEmails;
  }

  checkUnSecureAccess(unSecureAccesses: any[], delaySendDate?: Date): void {
    if (unSecureAccesses.length > 0) {
      this.setSecureAccess(unSecureAccesses, delaySendDate);
    }
    else {
      this.createSendNow(delaySendDate);
    }
  }

  setSecureAccess(unSecureAccesses: any[], delaySendDate?: Date): void {
    this.dialogRef = this.matDialog.open(this.mobileLoginWarningDialog);
    this.dialogRef.afterClosed().subscribe(dialogResponse => {
      if (dialogResponse) {
        this.isSending = true;
        this.selectedPatientId$
          .pipe(
            take(1),
            takeUntil(this._destroy$)
          )
          .subscribe((patientId) => {
            let setSecureAccessRequests: Observable<void>[] = [];
            _.each(unSecureAccesses, (unSecureAccess) => {
              let setSecureAccessRequest: Observable<void>;
              switch (unSecureAccess.type) {
                case 'contact':
                  let secureAccess: SecureAccessDto = new SecureAccessDto();
                  secureAccess.contactId = unSecureAccess.id;
                  secureAccess.isSecureAccess = true;

                  setSecureAccessRequest = this._patientClient.patient_PostContactSecureAccess(patientId, [secureAccess]);
                  setSecureAccessRequests.push(setSecureAccessRequest);
                  break;
                case 'dentist':
                  setSecureAccessRequest = this._dentistClient.dentist_PostDentistSecureAccess(unSecureAccess.id);
                  setSecureAccessRequests.push(setSecureAccessRequest);
                  break;
                case 'clinic':
                  setSecureAccessRequest = this._clinicClient.clinic_PostClinicSecureAccess(unSecureAccess.id);
                  setSecureAccessRequests.push(setSecureAccessRequest);
                  break;
                case 'other':
                  setSecureAccessRequest = this._userClient.user_PostOtherUser(unSecureAccess.email);
                  setSecureAccessRequests.push(setSecureAccessRequest);
                  break;
              }
            });

            from(setSecureAccessRequests)
              .pipe(
                mergeAll(1),
                toArray(),
                take(1),
                takeUntil(this._destroy$),
                catchError((error) => {
                  this._snackbar.open(error.message, 'Ok', { duration: 3000 });
                  this.isSending = false;
                  return null;
                })
              )
              .subscribe((res) => this.createSendNow(delaySendDate));
          });
      }
    });
  }

  saveEmailDraft(): void {
    if (this.messageFormGroup.valid) return;
    this.isDraftLoading = true;

    let sendNowMessage = this.messageFormGroup.value;

    combineLatest(
      this.selectedPatientId$,
      this.generateFileParameter(
        sendNowMessage.fileAttachments,
        sendNowMessage.localFileAttachments
      )
    )
      .pipe(
        take(1),
        map(([patientId, files]) => {
          let messageDraft: MessageDraftDto = new MessageDraftDto({
            id: sendNowMessage.messageDraftId,
            patientId: patientId,
            communicationDeskId: sendNowMessage.communicationDeskId,
            documentTemplateId: sendNowMessage.templateId,
            stationaryId: sendNowMessage.stationaryId,
            messageType: null,
            channelType: MessageChannelTypeEnum.Email,
            toRecipientId: sendNowMessage.recipient && sendNowMessage.recipient.type == 'contact' ? sendNowMessage.recipient.id : null,
            toProfessionalId: sendNowMessage.recipient && sendNowMessage.recipient.type == 'dentist' ? sendNowMessage.recipient.id : null,
            toClinicId: sendNowMessage.recipient && sendNowMessage.recipient.type == 'clinic' ? sendNowMessage.recipient.id : null,
            toOther: sendNowMessage.recipient && sendNowMessage.recipient.type == 'other' ? sendNowMessage.recipient.phoneEmail : null,
            ccRecipientId: sendNowMessage.cc && sendNowMessage.cc.type == 'contact' ? sendNowMessage.cc.id : null,
            ccProfessionalId: sendNowMessage.cc && sendNowMessage.cc.type == 'dentist' ? sendNowMessage.cc.id : null,
            ccClinicId: sendNowMessage.cc && sendNowMessage.cc.type == 'clinic' ? sendNowMessage.cc.id : null,
            ccOther: sendNowMessage.cc && sendNowMessage.cc.type == 'other' ? sendNowMessage.cc.phoneEmail : null,
            subject: sendNowMessage.subject,
            textBody: sendNowMessage.messageBody,
            htmlBody: sendNowMessage.messageBody,
            isHTML: true,
            eTag: sendNowMessage.messageDraftETag,
            userId: this.draftPopupOption.user,
            colorFlag: this.draftPopupOption.color,
            comments: this.draftPopupOption.comments,
          });

          return {
            messageDraft: messageDraft,
            files: files
          };
        })
      )
      .subscribe((result) => {
        if (result.messageDraft) {
          if (result.messageDraft.id)
            this._store$.dispatch(MessageDraftStoreActions.UpdateMessageDraftRequest({
              message: result.messageDraft,
              files: result.files
            }));
          else
            this._store$.dispatch(MessageDraftStoreActions.AddMessageDraftRequest({
              message: result.messageDraft,
              files: result.files
            }));
        }
      });
  }

  generateFileParameter(
    fileAttachments: any[],
    localFileAttachments: File[]
  ): Promise<FileParameter[]> {
    let fileAttachments$ = [];
    _.each(fileAttachments, (f: any) => {
      fileAttachments$.push(this.httpClient.get(f.locationUrl, { responseType: 'blob' }));
    });

    return new Promise(resolve => {
      forkJoin(...fileAttachments$)
        .pipe(
          defaultIfEmpty(null),
          take(1),
          switchMap((fileBlobs) => {
            let files: FileParameter[] = [];

            _.each(fileBlobs, (f: Blob, i: number) => {
              const data = new Blob([f], { type: f.type })
              files.push({
                data,
                fileName: fileAttachments[i].name.replace(new RegExp('/', 'g'), '_'),
              });
            });

            _.each(localFileAttachments, (f: File, i: number) => {
              const data = new Blob([f], { type: f.type })
              files.push({
                data,
                fileName: f.name.replace(new RegExp('/', 'g'), '_'),
              });
            });

            return of(files);
          }),
          map((files) => {
            return files;
          })
        )
        .subscribe((files) => {
          resolve(files);
        });
    });
  }

  validateMail() {
    let messageFormValue = this.messageFormGroup.value;
    if (messageFormValue.recipient.phoneEmail && messageFormValue.communicationDeskId && messageFormValue.subject && messageFormValue.messageBody) {
      return true
    }

    return false;
  }

  validateDraft() {
    let messageFormValue = this.messageFormGroup.value;
    if (messageFormValue.messageBody) {
      return true
    }

    return false;
  }

  backToFront() {
    this.data.flip.next({ payload: {}, side: this.data.side });
  }

  setRecipientType(value) {
    this.receiptientType = value;
  }

  setCcType(value) {
    this.ccType = value;
  }

  setOtherRecipient(value, isCc?: boolean) {
    let other = {
      phoneEmail: value,
      type: 'other',
      id: 0,
      name: null,
      phone: null,
      email: null
    }

    if (isCc) {
      this.messageFormGroup.get('cc').patchValue(other);
    }
    else {
      this.messageFormGroup.get('recipient').patchValue(other);
    }

    this.generateTemplateBody();
    this.generateStationary();
  }

  toggleIsSecured(isChecked: boolean): void {
    this.messageFormGroup.get('isSecured').setValue(isChecked);
  }

  openSaveToFolder(){
    this.dialog.open(FolderTreeViewSelectComponent, { isShowFileName: true }).afterClosed().subscribe((f: FolderTreeViewSelectModel) => {
      if (f && f.selectedFolderId) {
        this.savePDFandSend(f)
      }
    });
  }

  savePDFandSend(folderTreeViewSelectModel?: FolderTreeViewSelectModel){

    this.genrtMailPdf = true;
    this.isSending = true;

    this.sendNowMessage = this.messageFormGroup.value;
    this.sendNowMessage.isSecure = this.messageFormGroup.get('isSecured').value;

    setTimeout(() => {

      let mailContent = this.mailContent.nativeElement;

      let footer = mailContent.querySelectorAll('.stationary-footer');
      if(footer.length > 0){
        footer[0].setAttribute('style', 'display: none');
      }

      let opt = {
        margin: [0.5, 0.5, 1, 0.5],
        filename: 'Email.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: 'css' },
      };

      const footeroptions = {
        background: 'white',
        scale: 1,
        quality: 1
      };

      html2canvas(this.mailfootercontent.nativeElement, footeroptions).then((canvas) => {

        let img = canvas.toDataURL("image/jpeg");

        const today = moment();
        let publishedDt: any = today.format('MM/DD/YYYY, hh:mm a');

        let pdfWorker = html2pdf().from(mailContent).set(opt).toPdf().get('pdf').then((pdf) => {
          let totalPages = pdf.internal.getNumberOfPages();
          let pgHt = pdf.internal.pageSize.getHeight();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(11);
            pdf.setTextColor(150);

            let footerText: any = `Page ${i} of ${totalPages}   ${publishedDt}`
            if (footer.length > 0) {
              pdf.addImage(img, 'JPEG', 0.50, (pgHt - canvas.height / 72) - 0.30)
            } else {
              //pdf.text(footerText, 0.50, pgHt - 0.30);
            }
          }

          this.genrtMailPdf = false;
        });

        if (folderTreeViewSelectModel && folderTreeViewSelectModel.selectedFolderId) {
          pdfWorker.outputPdf('blob').then((blob: Blob) => {
            this.selectedPatientId$
              .pipe(take(1))
              .subscribe((patientId) => {
                const data = new Blob([blob], { type: blob.type })

                const file: FileParameter = {
                  data,
                  fileName: folderTreeViewSelectModel.fileName ? `${folderTreeViewSelectModel.fileName}.pdf` : `sendmessage-Mail.pdf`,
                };
                this._patientClient
                  .patient_PostFile(patientId, folderTreeViewSelectModel.selectedFolderId, null, file, StorageContentTypeEnum.Other)
                  .pipe(take(1))
                  .subscribe(
                    (result) => {
                      this.sendNowMessage.isSecure ? this.secureSendNow() : this.unsecureSendNow();
                      //this.resetAfterMailPdf(true);
                    },
                    (err) => {
                      //this.resetAfterMailPdf();
                      console.log(err);
                    }
                  );
              });
          });
        }
      })
    }, 1000)
  }

  bypassSecurity_TrustHtml(htmlcontent){
    if(htmlcontent){
      return this.sanitizer.bypassSecurityTrustHtml(htmlcontent.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
    }

    return false;

  }

  onSearchEmailTemplate(evt){
    let searchkeyword: any = evt.target.value;
    if (searchkeyword.length > 0) {
      this.filteredTemplateEmails = this.templateEmails.filter(option => option.name.toLowerCase().includes(searchkeyword.toLowerCase()));
      if(this.filteredTemplateEmails.length == 0){
        this.filteredTemplateEmails = this.templateEmails;
      }
    } else {
      this.filteredTemplateEmails = this.templateEmails;
      this.messageFormGroup.patchValue({
        templateId: null,
        messageBody: null
      });
      this.templateNameChanged.next(null);
    }
  }

  selectEmailTemplate(evt){
    let template:DocumentTemplateDto = evt.option.value;
    this.messageFormGroup.patchValue({
      templateId: template.id,
    });
    this.templateEmailInput.nativeElement.value = template.name;
  }

  private confirmGenerateMessage(isSkip?: boolean): Promise<boolean> {
    return new Promise(resolve => {
      if (isSkip) {
        resolve(true);
      }
      else {
        if (!this._overlayRef.hasAttached()) {
          const componentRef = this._overlayRef.attach(this._componentPortal);
          componentRef.instance.confirmText = "Do you want to generate new message?";
          componentRef.instance.positiveButtonText = "Yes";
          componentRef.instance.negativeButtonText = "No";
          componentRef.instance.showImage = false;
          componentRef.instance.boxpanelPositionClass = {
            position: "absolute",
            left: 0 ,
            top: 0,
            bottom: 0,
            right: 0,
            margin: "auto"
            
          }

          const sub = componentRef.instance.confirm
            .pipe(
              take(1),
              takeUntil(this._destroy$)
            )
            .subscribe((result) => {
              this._overlayRef.detach();
              resolve(result);
            });
          componentRef.onDestroy(() => sub.unsubscribe());
        }
      }
    });
  }
}
