import { Action, createReducer, on } from "@ngrx/store";
import * as AppointmentStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";
import { PatientsStoreActions } from '../patient-store';
import { AuthStoreActions } from "../auth-store";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(AppointmentStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, page: action.page || state.page, pageSize: action.pageSize || state.pageSize, selectedAppointmentId: null })),
  on(AppointmentStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.appointments, { ...s, isLoading: false, error: null })
  }),
  on(AppointmentStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //LOAD ONE
  on(AppointmentStoreActions.LoadOneRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(AppointmentStoreActions.LoadOneSuccess, (state, action) => (
    featureAdapter.upsertOne(action.appointment, { ...state, isLoading: false, error: null })
  )),
  on(AppointmentStoreActions.LoadOneFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //ADD
  on(AppointmentStoreActions.AddRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(AppointmentStoreActions.AddSuccess, (state, action) => (
    featureAdapter.upsertOne(action.appointment, { ...state, isLoading: false, error: null })
  )),
  on(AppointmentStoreActions.AddFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DELETE
  on(AppointmentStoreActions.DeleteRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedAppointmentId: state.selectedAppointmentId === action.id ? null : state.selectedAppointmentId })),
  on(AppointmentStoreActions.DeleteSuccess, (state, action) =>
    featureAdapter.removeOne(action.id, { ...state, isLoading: false, error: null})
  ),
  on(AppointmentStoreActions.DeleteFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(AppointmentStoreActions.SelectRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedAppointmentId: null  })),
  on(AppointmentStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedAppointmentId: action.appointment && action.appointment.id })),
  on(AppointmentStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //DESELECT
  on(AppointmentStoreActions.DeselectRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
  on(AppointmentStoreActions.DeselectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedAppointmentId: null })),
  on(AppointmentStoreActions.DeselectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(AppointmentStoreActions.UpdateRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(AppointmentStoreActions.UpdateSuccess, (state, action) =>
    featureAdapter.upsertOne(action.appointment, {...state, isLoading: false, error: null})
  ),
  on(AppointmentStoreActions.UpdateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UPDATE
  on(AppointmentStoreActions.UpdateNoShowRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(AppointmentStoreActions.UpdateNoShowSuccess, (state, action) =>
    featureAdapter.upsertOne(action.appointment, { ...state, isLoading: false, error: null })
  ),
  on(AppointmentStoreActions.UpdateNoShowFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //UNLOAD FOR PATIENT DESELECT
  on(PatientsStoreActions.DeselectRequest, (state, action) => {
    return featureAdapter.removeAll({...state});
  }),
  //UTILITY
  on(AppointmentStoreActions.ResetError, (state) => ({ ...state, error: null })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
