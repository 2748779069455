import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatBottomSheetRef, MatSnackBar, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { PatientClient } from 'src/app/shared/services/api.service';
import { PhotoGroupStoreEntity, PhotoTypeStoreEntity } from '../../../../root-store';

@Component({
  selector: 'move-photo-dialog',
  templateUrl: './move-photo-dialog.component.html',
  styleUrls: ['./move-photo-dialog.component.scss'],
})
export class MovePhotoDialogComponent implements OnInit {
  photoGroupIdTo: number;
  photoTypeIdTo: number;
  formControlPhotoGroupIdTo: FormControl;
  formControlPhotoTypeIdTo: FormControl;
  isWorking: boolean = false;
  photoGroups: PhotoGroupStoreEntity[];
  photoTypes: PhotoTypeStoreEntity[];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: MovePhotoDialogData,
    private _bottomSheetRef: MatBottomSheetRef<MovePhotoDialogComponent>,
    private _patientClient: PatientClient,
    private _snackBar: MatSnackBar,
    private ref: ChangeDetectorRef
  ) {
    this.formControlPhotoGroupIdTo = new FormControl('', Validators.required);
    this.formControlPhotoTypeIdTo = new FormControl('', Validators.required);

    this.photoGroups = this._data.photoGroups.filter(photogrps => photogrps.isActive);
    this.photoTypes = this._data.photoTypes.filter(photoTyps => photoTyps.isActive);
  }

  ngOnInit() {}

  save() {
    this.isWorking = true;
    this._patientClient
      .patient_MovePatientTreatmentImage(
        this._data.patientId,
        this._data.imageId,
        this.photoGroupIdTo,
        this.photoTypeIdTo)
      .pipe(take(1))
      .subscribe(() => {
        this.isWorking = false;
        this.done();
      }, (error) => {
        let response = JSON.parse(error.response);
        this.error(response.message);
        this.isWorking = false;
        this.ref.detectChanges();
      });
  }

  done() {
    this._snackBar.open("Successfully moved.", "Ok", {
      duration: 3000,
    });
    this._bottomSheetRef.dismiss(true);
  }

  error(message: string): void {
    this._snackBar.open(message, "Ok", {
      duration: 3000,
    });
  }
}

export interface MovePhotoDialogData {
  patientId: number;
  imageId: number;
  photoGroups: PhotoGroupStoreEntity[];
  photoTypes: PhotoTypeStoreEntity[];
}
